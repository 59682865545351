<div class="fixed inset-0 bg-gray-900 bg-opacity-60 z-50" *ngIf="invisibleModal"></div>


<!-- Main modal -->
<div id="createUserdefaultModal" *ngIf="invisibleModal" tabindex="-1" aria-hidden="true" class="flex fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-modal md:h-full">

  <div *ngIf="errors?.length > 0" class="mb-4 text-start p-3 box-errors">
    <ng-container *ngFor="let item of errors">
      <div class="d-inline-flex w-90">
        <i class="fas fa-info-circle pe-2"></i>
        <span>{{ item?.msg }}</span>
      </div>
    </ng-container>
  </div>

    <div class="relative p-4 w-full max-w-2xl h-full md:h-auto">
        <!-- Modal content -->
        <div class="relative p-4 bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5">
            <!-- Modal header -->
            <div class="flex justify-between items-center pb-4 mb-4 rounded-t border-b sm:mb-5 dark:border-gray-600">
                <h3 class="text-lg font-semibold text-gray-900 dark:text-white">
                    Registro
                </h3>
                <button type="button" (click)="closeModal()" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="createUserdefaultModal">
                    <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                    <span class="sr-only">Close modal</span>
                </button>
            </div>
            <!-- Modal body -->

            <form [formGroup]="form" (ngSubmit)="submit()" class="grid max-w-screen-md grid-cols-1 mx-auto gap-x-8 gap-y-6 sm:grid-cols-2">

              <div>
                <label for="nombre" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Nombre</label>
                <input type="text" id="nombre" formControlName="nombre" [ngClass]="{'error': form.controls.nombre.invalid && form.controls.nombre.dirty}"
                       class="block w-full p-3 text-sm text-gray-900 border border-gray-300 rounded-lg shadow-sm bg-gray-50"
                       placeholder="Juan">
              </div>

              <div *ngIf="form.getRawValue().type === 'PERSON'">
                <label for="apellido" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Apellido</label>
                <input type="text" id="apellido" formControlName="apellido" [ngClass]="{'error': form.controls.apellido.invalid && form.controls.apellido.dirty}"
                       class="block w-full p-3 text-sm text-gray-900 border border-gray-300 rounded-lg shadow-sm bg-gray-50"
                       placeholder="Pérez">
              </div>



              <div>
                  <div class="mb-3 relative">
                      <label for="phone" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">Número de teléfono</label>
                      <div class="flex items-center border border-gray-300 rounded-lg shadow-sm bg-gray-50 focus-within:border-blue-500 focus-within:ring-1 focus-within:ring-blue-500">
                          <span class="text-gray-800 pl-3">+58</span>
                          <input
                              type="text"
                              id="phone"
                              formControlName="telefono"
                              [ngClass]="{'error': form.controls.telefono.invalid && form.controls.telefono.dirty}"
                              (keypress)="onlyNumbers($event)"
                              class="block w-full p-3 pl-10 text-sm text-gray-900 bg-gray-50 border-0 rounded-lg focus:outline-none"
                              placeholder="4240000000">
                      </div>
                  </div>
                  <div class="form-control-feedback help-block" *ngIf="form.get('telefono').errors">
                      <div *ngIf="form.get('telefono').errors['required'] && !form.pristine">
                          Campo requerido
                      </div>
                  </div>
              </div>

              <div>
                <label for="username" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                  Username
                </label>
                <input type="text" id="username"
                  class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                  formControlName="username" [ngClass]="{'error': form.controls.username.invalid && form.controls.username.dirty}"
                  style="height: 53px;"
                  placeholder="jperez" required>
              </div>



            <div>
              <label for="correo" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                Correo electrónico
              </label>
              <input
              type="email"
              id="correo"
              class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light w-[575px]"
              formControlName="correo" [ngClass]="{'error': form.controls.correo.invalid && form.controls.correo.dirty}"
              style="height: 53px;"
              placeholder="juanperez@email.com" required>

              <div class="form-control-feedback help-block" *ngIf="form.get('correo').hasError('pattern')  && !form.pristine">
                <span>Email invalido</span>
            </div>
            </div>




            <div class="sm:col-span-2">
              <div class="mb-3 relative">
                  <label for="password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">Password</label>
                  <div class="flex items-center border border-gray-300 rounded-lg shadow-sm bg-gray-50 focus-within:border-blue-500 focus-within:ring-1 focus-within:ring-blue-500">
                      <input
                          [type]="showPassword ? 'text' : 'password'"
                          id="password"
                          formControlName="password"
                          [ngClass]="{'error': form.controls.password.invalid && form.controls.password.dirty}"
                          class="block w-full p-3 text-sm text-gray-900 bg-gray-50 border-0 rounded-lg focus:outline-none"
                          placeholder="Ingrese su contraseña">
                      <span class="text-gray-800 cursor-pointer mr-5" (click)="viewPassword()">
                          <i class="fas" [ngClass]="showPassword ? 'fa-eye-slash' : 'fa-eye'"></i>
                      </span>
                  </div>
              </div>

              <div class="form-control-feedback help-block" *ngIf="form.get('password').errors">
                  <div *ngIf="form.get('password').errors['required'] && !form.get('password').pristine">
                      Campo requerido
                  </div>
                  <div *ngIf="form.get('password').hasError('minlength') && !form.get('password').pristine">
                      Mínimo {{passwordMinLength}} caracteres
                  </div>
                  <div *ngIf="form.get('password').hasError('maxlength') && !form.get('password').pristine">
                      Máximo {{passwordMaxLength}} caracteres
                  </div>
              </div>
          </div>


              <button type="submit" [disabled]="!form.valid || submit_disabled"
                      [ngClass]="{'bg-gray-300': !form.valid || submit_disabled}"
                      class="h-10 w-32 border-none bg-blue-800 text-white rounded-md">
                Crear usuario
                <span *ngIf="submit_disabled"><i class="fa fa-spinner fa-spin ms-2"></i></span>
              </button>



            </form>


        </div>
    </div>
</div>
