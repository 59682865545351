import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { FaLayersComponent } from '@fortawesome/angular-fontawesome';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-expandable-rows-table',
  templateUrl: './expandable-rows-table.component.html',
  styleUrls: ['./expandable-rows-table.component.css'],
})
export class ExpandableRowsTableComponent implements OnInit {
  @Input() data: any[] = [];
  @Input() page: any;
  @Input() totalPages: any;
  @Input() totalRows: any;
  @Input() limit: any;
  @Input() selectedRange: any;
  @Input() dateSetting: any;
  @Input() isLoading: boolean = false;
  @Input() fromInfinite: boolean = false;

  bankList: any[] = [];
  stepsList: any[] = [];
  statusList: any[] = ['Todos', 'Completado', 'Pendiente', 'Anulado'];
  orderList = ['Fecha de registro', 'Prioridad'];
  transmitterList: any;
  receiverList: any;
  someFilter = false;
  constructor(public _user: UserService) {}

  ngOnInit(): void {
    console.log(this.data);
    console.log(this.data[0]);
    this.getRows();
    this.loadDynamicdata();
  }

  toggleExpand(item: any) {
    console.log(item);
  }

  closeFilters() {
    this._user.onFilterItemSelected.emit({ toClose: true });
  }

  validateInfo(item: any) {
    if (item.toString().length > 0) {
      return item;
    } else {
      return '-';
    }
  }

  getPriorityClass(name: string): string {
    if (name.toLowerCase() == 'baja') {
      return TAG_CLASES[0];
    } else if (name.toLowerCase() == 'media') {
      return TAG_CLASES[1];
    } else if (name.toLowerCase() == 'alta') {
      return TAG_CLASES[2];
    } else {
      return '';
    }
  }

  getProgressClass(name: string): string {
    if (name.toLowerCase() == 'pendiente') {
      return TAG_CLASES[2];
    } else if (
      name.toLowerCase() == 'completado' ||
      name.toLowerCase() == 'finalizado'
    ) {
      return TAG_CLASES[1];
    } else {
      return TAG_CLASES[3];
    }
  }

  geItemInfo(item: any) {
    debugger;
    this._user.onOpenOrderDetail.emit(item);
  }

  goToOrganization(item: any) {
    this._user.onGoToOrganization.emit(item);
  }

  gotoProvider(item: any) {
    this._user.onGotoProvider.emit(item);
  }

  getRows() {
    this._user.onDownloadData.subscribe((data: any) => {
      console.log(data);
      this.downloadCSV('datos.csv');
    });
  }

  parseDate(dateStr: string) {
    const [day, month, year] = dateStr.split('-').map(Number);
    return new Date(year, month - 1, day);
  }

  diffDays(fecha1: string, fecha2: string) {
    const date1: any = this.parseDate(fecha1);
    const date2: any = this.parseDate(fecha2);

    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    if (diffDays == 0) {
      return 'Un dia';
    } else if (diffDays == 1) {
      return 'Un dia';
    } else {
      return diffDays + ' dias';
    }
  }

  convertToCSV(array: any[]): string {
    const header = Object.keys(array[0]).join(',') + '\n';
    const rows = array.map((obj) => Object.values(obj).join(',')).join('\n');
    console.log(header, rows);
    return header + rows;
  }

  // Función para descargar el CSV
  downloadCSV(filename: string): void {
    const csv = this.convertToCSV(this.data);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  findLabel(item: string, type: string) {
    let label = '';
    this.someFilter = true;
    if (type == 'selectedResponsable') {
      this.stepsList.forEach((step: any) => {
        if (step.code == item) {
          label = 'Etapa: ' + step.name;
        }
      });
    }
    if (type == 'selectedStatus') {
      this.statusList.forEach((status: any) => {
        if (status == item) {
          label = 'Estatus: ' + status;
        }
      });
    }
    if (type == 'bank_filter') {
      this.bankList.forEach((bank: any) => {
        if (bank.code == item) {
          label = 'Banco de origen: ' + bank.name;
        }
      });
    }
    if (type == 'sort_filter') {
      this._user.sort_filter == 'dateEnd'
        ? (label = 'Ordenar por: ' + this.orderList[0])
        : (label = 'Ordenar por: ' + this.orderList[1]);
    }
    if (type == 'emisor_filter') {
      this.transmitterList.forEach((itemList: any) => {
        if (itemList.value == item) {
          label = 'Pagador: ' + itemList.text;
        }
      });
    }
    if (type == 'receptor_filter') {
      this.receiverList.forEach((itemList: any) => {
        if (itemList.value == item) {
          label = 'Beneficiario: ' + itemList.text;
        }
      });
    }
    if (type == 'selectedOrden') {
      label = 'Número de orden: ' + item;
    }
    if(type=='selectedSignature') {
      label = 'Pagos: ' + item;
    }
    if(type=='type_expenses') {
      label = 'Tipo de Gasto: ' + item;
    }




    return label;
  }

  async loadDynamicdata() {
    this._user.onBankListLoaded.subscribe((data: any) => {
      console.log(data);
      this.bankList = data;
      const found = this.bankList.find(
        (element) => element.name.toString().toLowerCase() == 'todos'
      );
      if (!found) {
        this.bankList.unshift({ code: '', name: 'Todos' });
      }
      //this.bankList.unshift({code: '', name: 'Todos'})
    });
    this._user.onTransmitterListLoaded.subscribe((data: any) => {
      console.log(data);
      this.transmitterList = data;
      const found = this.bankList.find(
        (element) => element?.text?.toString()?.toLowerCase() == 'todos'
      );
      if (!found) {
        this.bankList.unshift({ value: '', text: 'Todos' });
      }
      //this.transmitterList.unshift({value: '', text: 'Todos'})
    });

    this._user.onReceiverListLoaded.subscribe((data: any) => {

      this.receiverList = data;
      const found = this.bankList.find(
        (element) => element?.text?.toString()?.toLowerCase() == 'todos'
      );
      if (!found) {
        this.bankList.unshift({ value: '', text: 'Todos' });
      }
    });

    const steps = await this._user.getSteps({});
    this.stepsList = steps?.resp;
  }

  cleanFilter(index: number) {
    this._user.onCleanFilter.emit(index);
  }

  validateLoading() {
    if (this.fromInfinite) {
      return true;
    } else {
      return !this.isLoading;
    }
  }

  calculateDays(inputDate: any) {
    const [day, month, year] = inputDate.split('-').map(Number);
    const targetDate = new Date(year, month - 1, day);
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    const timeDiff = targetDate.getTime() - currentDate.getTime();
    const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));
    const response: any = {
      diasFaltantes: daysDiff,
    };
    if (daysDiff === 0) {
      response.fechaEstado = 'Hoy';
    } else if (daysDiff === 1) {
      response.fechaEstado = 'Mañana';
    }
    return response;
  }

  validateSomeFilter() {
    return (
      this._user.selectedResponsable ||
      this._user.selectedStatus ||
      this._user.bank_filter ||
      this._user.sort_filter ||
      this._user.emisor_filter ||
      this._user.receptor_filter ||
      this._user.selectedOrden ||
      this._user.selectedSignature ||
      this._user.type_expenses
    );
  }
}

const TAG_CLASES = [
  'bg-blue-100 text-blue-400 text-xs font-medium px-2.5 py-0.5 rounded',
  'bg-green-100 text-green-400 text-xs font-medium px-2.5 py-0.5 rounded',
  'bg-yellow-100 text-red-400 text-xs font-medium px-2.5 py-0.5 rounded',
  'bg-red-100 text-red-500 text-xs font-medium px-2.5 py-0.5 rounded',
];
