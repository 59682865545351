import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth/auth.service';
import { UserService } from 'src/app/services/user/user.service';
import * as alertifyjs from 'alertifyjs';


@Component({
  selector: 'app-edit-profile-user',
  templateUrl: './edit-profile-user.component.html',
  styleUrls: ['./edit-profile-user.component.css']
})
export class EditProfileUserComponent implements OnInit {

    invisibleModal:boolean = true;
    @Output() close = new EventEmitter<void>();

    isAuth:any;
    sessionUser:any;
    form:any;
    submit_disabled:boolean = false;
    person:any;

    email = "^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$";


  constructor(
    private formBuilder: FormBuilder,
    public _auth : AuthService,
    public _user: UserService
  ) {

   }

   ngOnInit() {
    this.form = this.formBuilder.group({
        telefono: ['', [Validators.required]],
        correo: ['', [Validators.required, Validators.pattern(this.email)]],
    });

    this._auth.auth$.subscribe((rep: any) => {
        this.isAuth = rep;
        this.sessionUser = rep ? this._auth.sessionUser : null;

        if (this.sessionUser) {
            this.getPerson().then(() => {
                this.getValueContacts();
            });
        }
    });
}

  async getPerson(){
    try {
      const resp = await this._user.getPersonbyCode({ code: this.sessionUser.person_code });
      this.person = resp.resp;
    } catch (error) {
      console.log(`Error ${error}`)
    }
  }


  getValueContacts() {
    let phone = '';
    let email = '';

    if (this.person && this.person.contacts) {
        this.person.contacts.forEach((contact: any) => {
            if (contact.type_contact.code === 'EMAIL_USER') {
                email = contact.contact;
            } else if (contact.type_contact.code === 'PHONE_USER') {
                phone = contact.contact;
            }
        });
    }

    this.form.patchValue({
        telefono: phone.replace('+58', ''),
        correo: email || '',
    });
}


async editUser() {
    debugger

    if (this.form) {
      this.submit_disabled = true;

      const form = this.form.getRawValue();
      let number = form.telefono;
      let phone = number ? number.toString() : "";

      const telefonoFormateado = phone.replace(/^\+58/, '');


      let contacts = [];
      if (telefonoFormateado) {
        contacts.push({
          type_contact: "PHONE_CELL",
          verificated_contact: false,
          contact: `+58${telefonoFormateado}`
        });
      }
      if (form.correo) {
        contacts.push({
          type_contact: "EMAIL",
          verificated_contact: false,
          contact: form.correo
        });
      }

      const form_resp = {
        code_user: this.sessionUser?.person_code || "",
        audience: "NATURAL",
        type_contact: "PHONE_USER",
        contact: contacts.length > 0 ? contacts : [{}],
        verificated_contact: false,
        person_id: this.person?.person?._id,
        role: form.type === "COMPUTER" ? "COMPUTER_ROLE" : "USER_ROLE"
      };

      try {

        const resp = await this._user?.edit_user(form_resp);

        if(resp){
          alertifyjs.success(`Usuario actualizado exitosamente`)
          this.submit_disabled = false;
          this.closeModal();
        }else{
          alertifyjs.error(`Error al actualizar al usuario`)
        }

      } catch (error) {
        console.log(error);
        this.submit_disabled = false;
      }
    }
  }

  closeModal(){
    this.invisibleModal = !this.invisibleModal;
    this.close.emit();
  }

  onlyNumbers(event: KeyboardEvent) {
    const char = String.fromCharCode(event.which);
    if (!/[\d]/.test(char)) {
        event.preventDefault();
    }
}

}
