




 <ng-template #content let-offcanvas>
    <div class="offcanvas-header">
        <div class="container ">
            <div class="row">
                <div class="col-12 col-md-10 offset-md-1 ">
                    <div class="row">
                        <div class="col-10">
                            <div class="text-capitalize h5 text-secondary mt-2 ">
                               Revisar Orden de Pago

                            </div>
                        </div>
                        <div class="col-2 text-end pt-2">
                            <button type="button" class="btn-close" aria-label="Close" (click)="offcanvas.dismiss('Cross click'); "></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="offcanvas-body">
        <div class=" container">

            <div class="row">
                <div class=" col-12 col-md-10 offset-md-1">

                  <!--  {{invoice_rev?.workflow?.current?.step}} -->

                    <div *ngIf="invoice_rev?.workflow?.current?.step  == 'PAYMENT_REQUEST'">

                        <div class="form-group mt-4" >

                            <div class="form-floating">
                                <select [(ngModel)]="invoice_rev_form.action" class="form-select" id="floatingSelect" aria-label="Floating label select example">
                                <option   value="">Seleccione</option>
                                <option value="A">Aprobar</option>
                                <option value="T">Mover a Papelera</option>


                                </select>
                                <label for="floatingSelect">Acción</label>
                            </div>
                        </div>

                        <div class="form-group mt-4">
                            <div class="form-floating">
                                <textarea [(ngModel)]="invoice_rev_form.observaciones" class="form-control" placeholder="Leave a comment here" id="floatingTextarea2" style="height: 100px"></textarea>
                                <label for="floatingTextarea2">Observaciones</label>
                            </div>
                        </div>


                        <div class="col-12 text-end">

                            <button type="button" class="btn btn-primary mt-4" [disabled]="rev_loading" (click)="  response_invoice_action()"> Continuar </button>


                        </div>
                    </div>

                    <div *ngIf="invoice_rev?.workflow?.current?.step  == 'REVIEW_AND_APPROVAL_OF_PAYMENT_REQUEST'">

                        <div class="form-group mt-4" >

                            <div class="form-floating">
                                <select [(ngModel)]="invoice_rev_form.action" class="form-select" id="floatingSelect" aria-label="Floating label select example">
                                <option   value="">Seleccione</option>
                                <option value="A">Aprobar</option>
                                <option value="B">Devolver</option>


                                </select>
                                <label for="floatingSelect">Acción</label>
                            </div>
                        </div>

                        <div class="form-group mt-4">
                            <div class="form-floating">
                                <textarea [(ngModel)]="invoice_rev_form.observaciones" class="form-control" placeholder="Leave a comment here" id="floatingTextarea2" style="height: 100px"></textarea>
                                <label for="floatingTextarea2">Observaciones</label>
                            </div>
                        </div>


                        <div class="col-12 text-end">

                            <button type="button" class="btn btn-primary mt-4" [disabled]="rev_loading"  (click)="   response_invoice_action()"> Continuar </button>

                        </div>
                    </div>


                    <!-- se oulta la seleccion de cuenta bancaria-->
                    <div *ngIf="invoice_rev?.workflow?.current?.step  == 'PAYMENT_AUthis.invoice_rev?.invoiceTHORIZATION'">

                        <!--
                        <div class="col-12 col-md-6 mb-3">
                            <div class=" text-secondary">Organización</div>
                            <div class="fw-bolder text-dark"> {{invoice_rev?.invoice?.customer_suscriptor?.name }} <div class="fw-normal small">{{ invoice_rev?.invoice?.identity_suscriptor?.code_identity?.code}}-{{ invoice_rev?.invoice?.identity_suscriptor?. identity}}</div> </div>

                        </div>

                        <div class="form-group mt-4 mb-3" >

                            <div class="form-floating">
                                <select [(ngModel)]="invoice_rev_form.client_suscriptor" class="form-select" id="floatingSelect" aria-label="Floating label select example">
                                <option   value="">Seleccione</option>
                                <option *ngFor="let item of invoice_rev.client_suscriptor_list"  [value]="item._id"> {{item.bank?.name}} - {{item.payment_method?.name}} / {{item.account_number}}</option>

                                </select>
                                <label for="floatingSelect">Cuenta bancaria de la organización
                                </label>
                            </div>
                        </div>

                        <div class="col-12 col-md-6 mb-3 mt-3">
                            <div class=" text-secondary">Proveedor</div>
                            <div class="fw-bolder text-dark"> {{invoice_rev?.invoice?.customer?.name }} <div class="fw-normal small">{{ invoice_rev?.invoice?.identity?.code_identity?.code}}-{{ invoice_rev?.invoice?.identity?. identity}}</div> </div>
                        </div>

                        <div class="form-group mt-4 mb-3" >
                            <div class="form-floating">
                                <select [(ngModel)]="invoice_rev_form.client_payment" class="form-select" id="floatingSelect" aria-label="Floating label select example">
                                <option   value="">Seleccione</option>
                                <option *ngFor="let item of invoice_rev.client_payment_list" [value]="item._id">{{item.bank?.name}} - {{item.payment_method?.name}} / {{item.account_number}}</option>


                                </select>
                                <label for="floatingSelect">Cuenta bancaria del proveedor
                                </label>
                            </div>
                        </div>

                        -->

                        <div class="form-group mt-4" >

                            <div class="form-floating">
                                <select [(ngModel)]="invoice_rev_form.action" class="form-select" id="floatingSelect" aria-label="Floating label select example">
                                <option   value="">Seleccione</option>
                                <option value="A">Aprobar</option>
                                <option value="D">Anular</option>

                                </select>
                                <label for="floatingSelect">Acción</label>
                            </div>
                        </div>

                        <div class="form-group mt-4">
                            <div class="form-floating">
                                <textarea [(ngModel)]="invoice_rev_form.observaciones" class="form-control" placeholder="Leave a comment here" id="floatingTextarea2" style="height: 100px"></textarea>
                                <label for="floatingTextarea2">Observaciones</label>
                            </div>
                        </div>


                        <div class="col-12 text-end">

                            <button type="button" class="btn btn-primary mt-4" [disabled]="rev_loading"  (click)="  response_invoice_action()"> Continuar </button>

                        </div>
                    </div>



                    <div *ngIf="invoice_rev?.workflow?.current?.step  == 'PAYMENT_EXECUTION'">

                        <div class="text-center" *ngIf="invoice_rev?.installMent?.payment_status?.code == 'PaymentPending'">
                            <div class="alert alert-warning p-2 text-start" style="background-color: #f8d7da3b" role="alert">
                                <span class="text-dark "><span class="font-weight-bold">Realiza los pagos correspondientes y completa la cuota</span>
                                </span>
                            </div>
                            <button type="button" class="btn btn-primary mt-4" (click)="pocisionBottom2(pago)"  [routerLink]="['/console/payment-provider/'+invoice_rev?.invoice?.code+'/'+invoice_rev?.installMent?.code]"> Realizar pago  </button>
                        </div>
                        <div *ngIf="invoice_rev?.installMent?.payment_status?.code != 'PaymentPending'">

                        <div class="form-group mt-4">

                            <div class="form-floating">
                                <select [(ngModel)]="invoice_rev_form.action" class="form-select" id="floatingSelect" aria-label="Floating label select example">
                                <option   value="">Seleccione</option>
                                <option value="A" >Aprobar</option>

                                </select>
                                <label for="floatingSelect">Acción</label>
                            </div>
                        </div>

                        <div class="form-group mt-4" >
                            <div class="form-floating">
                                <textarea [(ngModel)]="invoice_rev_form.observaciones" class="form-control" placeholder="Leave a comment here" id="floatingTextarea2" style="height: 100px"></textarea>
                                <label for="floatingTextarea2">Observaciones</label>
                            </div>
                        </div>


                        <div class="col-12 text-end">

                            <button type="button" class="btn btn-primary mt-4" (click)="  response_invoice_action()"> Continuar </button>

                        </div>

                    </div>

                    </div>

                    <div *ngIf="invoice_rev?.workflow?.current?.step  == 'RECONCILIATION_AND_AUDIT' ||
                    invoice_rev?.workflow?.current?.step  == 'AUDIT'  ||
                    invoice_rev?.workflow?.current?.step  == 'FILING_AND_DOCUMENTATION'">

                        <div class="form-group mt-4" >

                            <div class="form-floating">
                                <select [(ngModel)]="invoice_rev_form.action" class="form-select" id="floatingSelect" aria-label="Floating label select example">
                                <option   value="">Seleccione</option>
                                <option value="A">Aprobar</option>
                                <option value="D">Anular</option>


                                </select>
                                <label for="floatingSelect">Acción</label>
                            </div>
                        </div>

                        <div class="form-group mt-4">
                            <div class="form-floating">
                                <textarea [(ngModel)]="invoice_rev_form.observaciones" class="form-control" placeholder="Leave a comment here" id="floatingTextarea2" style="height: 100px"></textarea>
                                <label for="floatingTextarea2">Observaciones</label>
                            </div>
                        </div>


                        <div class="col-12 text-end">

                            <button type="button" class="btn btn-primary mt-4" [disabled]="rev_loading"  (click)="   response_invoice_action()"> Continuar </button>

                        </div>
                    </div>



                </div>
            </div>
        </div>
    </div>
</ng-template>



<ng-template #content2 let-offcanvas>
    <div class="offcanvas-header">
        <div class="container ">
            <div class="row">
                <div class="col-12  ">
                    <div class="row">
                        <div class="col-10">
                            <div class="text-capitalize h5 text-secondary mt-2 ">
                                Secuencia de Pagos
                            </div>
                        </div>
                        <div class="col-2 text-end pt-2">
                            <button type="button" class="btn-close" aria-label="Close" (click)="offcanvas.dismiss('Cross click'); "></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="offcanvas-body">
        <div class=" container">

            <div class="row">
                <div class=" col-12 ">

                    <ul>
                      <li *ngFor="let item of getSequence()" class="mb-3">


                        <strong [ngClass]="{'text-warning': item.state == step}">{{ stepsObj[item.state]?.name }}</strong>
                        <span *ngIf="item.state != step && !item?.completed "   class="fas fa-check text-success ps-1 small"></span>

                        <div class="ms-4">
                            <div *ngIf="item.back"> <span class="text-secondary">Anterior: </span>{{  stepsObj[item.back]?.name  }}</div>
                            <div *ngIf="item.next"> <span class="text-secondary">Siguiente: </span>  {{  stepsObj[item.next]?.name  }}</div>
                        </div>

                      </li>
                    </ul>


                </div>
            </div>
        </div>
    </div>
</ng-template>



<ng-template #pago let-offcanvas>
    <div class="offcanvas-header">
        <div class="container ">
            <div class="row">
                <div class="col-12 col-md-10 offset-md-1 ">
                    <div class="row">
                        <div class="col-10">
                            <div class="text-capitalize h5 text-secondary mt-2 ">
                               Realizar Pago

                            </div>
                        </div>
                        <div class="col-2 text-end pt-2">
                            <button type="button" class="btn-close" aria-label="Close" (click)="offcanvas.dismiss('Cross click'); "></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="offcanvas-body">
        <div class=" container">

            <div class="row">
                <div class=" col-12 col-md-10 offset-md-1">

                    <div class="text-start">

                        <app-post-provider-subscriptor  [code_invoice]="params_payment?.code_invoice" [code_installment]="params_payment?.code_installment" [code_person]="params_payment?.code_person"  [femicion]="params_payment?.femicion"    (resetStatus)="setResetStatus($event)" ></app-post-provider-subscriptor>
                    </div>
                </div>
            </div>

        </div>
    </div>
</ng-template>



<ng-template #actionwf let-data>


<ng-container >





    <div  class="row"   *ngIf="data?.item2?.workflow?.current?.step  == 'PAYMENT_REQUEST' ">

        <div class="col-6 text-right" >


            <!-- || data?.item2?.workflow?.current?.user==this._auth.sessionUser?.uid-->
            <button   (click)="changeSubStatus(data?.item2?._id, data?.item2?.workflow?.current?.step)"   [ngClass]="{'btn-warning': data?.item2?.workflow?.current?.taken != true,'btn-secondary': data?.item2?.workflow?.current?.taken == true}"  class="btn  btn-sm  mt-4 me-2 small" >
                {{data?.item2?.workflow?.current?.taken=== true?'Inactivar revisión' : 'Activar revisión'}}
            </button>

        </div>
        <div class="col-6 text-end" *ngIf="!rev_loading">
            <button type="button" class="btn btn-danger mt-4 me-2 small" [disabled]="rev_loading" (click)="response_invoice_action2(data?.item,data?.item2,{action:'R'},'Anulado','anular')"> Anular</button>
            <button type="button" class="btn btn-primary mt-4 small" [disabled]="rev_loading" (click)="response_invoice_action2(data?.item,data?.item2,{action:'A'}, 'Aprobado','aprobar')"> Aprobar</button>

        </div>


    </div>

    <div class="row"    *ngIf="(data?.item2?.workflow?.current?.step  == 'REVIEW_AND_APPROVAL_OF_PAYMENT_REQUEST' ||
    data?.item2?.workflow?.current?.step  == 'PAYMENT_AUTHORIZATION')  && _auth.IsPermitid(['OWNER', data?.item2?.workflow?.current?.step])
     ">

            <div class="col-6 text-right" [class.col-12]="rev_loading" [class.col-6]="!rev_loading" >
                <!-- || data?.item2?.workflow?.current?.user==this._auth.sessionUser?.uid

                *ngIf="!data?.item2?.workflow?.current?.taken "-->
                <button   (click)="changeSubStatus(data?.item2?._id, data?.item2?.workflow?.current?.step)"  [ngClass]="{'btn-warning': data?.item2?.workflow?.current?.taken != true,'btn-secondary': data?.item2?.workflow?.current?.taken == true}"  class="btn   btn-sm  mt-4 me-2 small" >
                    {{data?.item2?.workflow?.current?.taken=== true?'Inactivar revisión' : 'Activar revisión'}}
                </button>
            </div>


            <div class="col-6 text-end" *ngIf="!rev_loading" >
            <button type="button" class="btn btn-danger mt-4 me-2" [disabled]="rev_loading" (click)="response_invoice_action2(data?.item,data?.item2,{action:'B'},'Devuelto','devolver')"> Devolver</button>
            <button type="button" class="btn btn-primary mt-4" [disabled]="rev_loading" (click)="response_invoice_action2(data?.item,data?.item2,{action:'A'}, 'Aprobado','aprobar')"> Aprobar</button>

            </div>
    </div>

    <div class="row" *ngIf="data?.item2?.workflow?.current?.step  == 'PAYMENT_EXECUTION' && _auth.IsPermitid(['OWNER', data?.item2?.workflow?.current?.step])">


        <div class="col-12 text-right" *ngIf="!rev_loading" >
            <div class="text-center" *ngIf="data?.item2?.payment_status?.code == 'PaymentPending'">
                <div class="alert alert-warning p-2 text-start" style="background-color: #f8d7da3b" role="alert">
                    <span class="text-dark "><span class="font-weight-bold">Realiza los pagos correspondientes y completa la cuota</span>
                    </span>
            </div>
            </div>
        </div>


        <div class="col-6 " >
            <!-- || data?.item2?.workflow?.current?.user==this._auth.sessionUser?.uid-->
            <button   (click)="changeSubStatus(data?.item2?._id, data?.item2?.workflow?.current?.step)"  [ngClass]="{'btn-warning': data?.item2?.workflow?.current?.taken != true,'btn-secondary': data?.item2?.workflow?.current?.taken == true}"  class="btn  btn-sm   mt-4 me-2 small" >
                {{data?.item2?.workflow?.current?.taken=== true?'Inactivar revisión' : 'Activar revisión'}}
            </button>
        </div>

      <!--
        <pre>{{ pending_payment?.installMents[data?.item2?.code] | json }}</pre>  
      -->
    
        <div class="col-6 text-end" *ngIf="!rev_loading  && (data?.item2?.workflow?.current?.user==this._auth.sessionUser?.uid || _auth.IsPermitid(['PAYMENT_AUTHORIZATION','AUTHORIZATION_PAYMENT_EXECUTION','OWNER'] ))">


            <button type="button"  class="btn btn-success mt-4 me-2" [disabled]="rev_loading" (click)="response_invoice_action2(data?.item,data?.item2,{action:'A'}, 'Aprobado','Aprobar')" *ngIf="data?.item2?.workflow?.current.step==='PAYMENT_EXECUTION' && pending_payment?.installMents[data?.item2?.code]?.paymentNext "> Aprobar</button>
            <button type="button"  class="btn btn-danger mt-4 me-2" [disabled]="rev_loading" (click)="response_invoice_action2(data?.item,data?.item2,{action:'B'},'Devuelto','devolver')"> Devolver</button>
            <button type="button"  class="btn btn-primary mt-4"  (click)="pocisionBottom3(pago,{ code_invoice:data?.item?.code, code_installment: data?.item2?.code,code_person:data?.item?.customer?.code,femicion: data?.item?.params ?.femicion}) " *ngIf="pending_payment?.installMents[data?.item2?.code]?.paymentActive " > Realizar pago de {{pending_payment?.installMents[data?.item2?.code][pending_payment?.installMents[data?.item2?.code]?.type].paymentMissing }} 
                {{pending_payment?.installMents[data?.item2?.code]?.currency}} </button>

        </div>
     </div>



     <div class="row" *ngIf="data?.item2?.workflow?.current?.step  == 'AUTHORIZATION_PAYMENT_EXECUTION' && _auth.IsPermitid(['OWNER', data?.item2?.workflow?.current?.step])">

        <div class="col-6 text-right" [class.col-12]="rev_loading" [class.col-6]="!rev_loading" >
            <!-- || data?.item2?.workflow?.current?.user==this._auth.sessionUser?.uid-->
            <button   (click)="changeSubStatus(data?.item2?._id, data?.item2?.workflow?.current?.step)" class="btn btn-secondary  mt-4 me-2 small" *ngIf="!data?.item2?.workflow?.current?.taken ">
                {{data?.item2?.workflow?.current?.taken=== true?'Inactivar revisión' : 'Activar revisión'}}
            </button>
        </div>

            <div class="col-6 text-end" *ngIf="!rev_loading">


                <button type="button" class="btn btn-warning mt-4 me-2" [disabled]="rev_loading" (click)="response_invoice_action2(data?.item,data?.item2,{action:'B'},'No autorizado','No autorizar')"> No autorizar </button>
                <button type="button" class="btn btn-primary mt-4" [disabled]="rev_loading" (click)="response_invoice_action2(data?.item,data?.item2,{action:'A'}, 'Autorizado','Autorizar')"> Autorizar </button>

            </div>
     </div>


     <div class="row" *ngIf="data?.item2?.workflow?.current?.step  == 'RECONCILIATION_AND_AUDIT' && _auth.IsPermitid(['OWNER', data?.item2?.workflow?.current?.step])">

        <div class="col-6 text-right" [class.col-12]="rev_loading" [class.col-6]="!rev_loading" >
            <!-- || data?.item2?.workflow?.current?.user==this._auth.sessionUser?.uid-->
            <button   (click)="changeSubStatus(data?.item2?._id, data?.item2?.workflow?.current?.step)" class="btn btn-secondary  mt-4 me-2 small" *ngIf="!data?.item2?.workflow?.current?.taken ">
                {{data?.item2?.workflow?.current?.taken=== true?'Inactivar revisión' : 'Activar revisión'}}
            </button>
        </div>

            <div class="col-6 text-end" *ngIf="!rev_loading">


                <button type="button" class="btn btn-danger mt-4 me-2" [disabled]="rev_loading" (click)="response_invoice_action2(data?.item,data?.item2,{action:'B'},'No devuelto','devolver')">Devolver</button>
                <button type="button" class="btn btn-success mt-4" [disabled]="rev_loading" (click)="response_invoice_action2(data?.item,data?.item2,{action:'A'}, 'aprobado','aprobar')">Aprobar</button>

            </div>
     </div>



     <div class="row" *ngIf="data?.item2?.workflow?.current?.step  == 'AUDIT' && _auth.IsPermitid(['OWNER', data?.item2?.workflow?.current?.step])">

        <div class="col-6 text-right" [class.col-12]="rev_loading" [class.col-6]="!rev_loading" >
            <!-- || data?.item2?.workflow?.current?.user==this._auth.sessionUser?.uid-->
            <button   (click)="changeSubStatus(data?.item2?._id, data?.item2?.workflow?.current?.step)" class="btn btn-secondary  mt-4 me-2 small" *ngIf="!data?.item2?.workflow?.current?.taken ">
                {{data?.item2?.workflow?.current?.taken=== true?'Inactivar revisión' : 'Activar revisión'}}
            </button>
        </div>

        <div class="col-6 text-end" *ngIf="!rev_loading">
            <button type="button" class="btn btn-warning mt-4 me-2" [disabled]="rev_loading" (click)="response_invoice_action2(data?.item,data?.item2,{action:'A'},'Inconsistente','marcar como Inconsistente')">Inconsistente </button>
            <button type="button" class="btn btn-primary mt-4" [disabled]="rev_loading" (click)="response_invoice_action2(data?.item,data?.item2,{action:'A'}, 'Auditado','marcar como auditada')"> Auditar </button>

        </div>
    </div>


    <div *ngIf="data?.item2?.workflow?.current?.step  == 'FILING_AND_DOCUMENTATION' ">

        <div class="col-12 text-end" *ngIf="!rev_loading">
            <button type="button" class="btn btn-success mt-4" [disabled]="rev_loading" (click)="response_invoice_action2(data?.item,data?.item2,{action:'A'}, 'Finalizado')"> Finalizar </button>

        </div>
    </div>

</ng-container>


    <div class="col-12" *ngIf="data?.item2?.workflow?.current?.taken && (data?.item2?.workflow?.current?.user!=this._auth.sessionUser?.uid)">
        <ng-container >
            <div class="mt-2">
                En revisión por:
                <div class="font-weight-bold">{{ data?.item2?.workflow?.current?.user_fullname }}</div>
            </div>
        </ng-container>
    </div>


</ng-template>

<div class="mt-md-3 container-fluid">

    <div class="row ">
        <div class=" col-12 ">
            <div class="row rmt-2" *ngIf="!loading_person && !isTypeComponet">
                <div class="col-12  col-md-10 offset-md-1">

                    <h5 class="mb-1 text-secondary mb-4 text-center">
                        <button *ngIf="view != 1" (click)="closeInstallment()" type="button" class="btn-close float-start " aria-label="Close"></button>

                        <i class="  fas fa-user"></i>Ordenes De Pago
                    </h5>
                    <div *ngIf="!loading_person && person?.person" class="text-center mb-3">
                        <div class="text-capitalize h4 text-dark">{{person?.person?.name}} {{person?.person?.lastName}}</div>
                        <div> {{getIdentity(person)}}</div>
                        <div> {{getContact(person)}}</div>

                        <div class="text-center mt-2">
                            <div>


                                <button *ngIf="!env?.production && _auth.IsPermitid(['OWNER'])" (click)="restClient()" class="btn  btn-outline-dark btn-sm ">
                                    <span >Eliminar ordenes de pago</span>
                                </button>
                            </div>
                            <!-- <div>
                                <button *ngIf="!env?.production" type="button" class="btn btn-outline-primary mt-2 btn-sm" [routerLink]="['/console/credit-save']" (click)="setIdentity()"> Nueva compra financiada</button>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>


            <ng-container *ngIf="!loading && person?.person" class="mt-2">
                <div class="row rmt-2" >
                    <div  [ngClass]="{'col-md-10 offset-md-1': !isTypeComponet}" class="col-12 ">







                        <ng-container *ngFor="let item of resp_invoiceCredits">

                            <div class="mt-5 my-3 p-3 bg-body rounded shadow-sm">

                                <div class="alert border-1 border-danger bg-white mt-3 text-secondary" role="alert" *ngIf="response_massive_process.length>0" >
                                    <div class="fw-bold text-danger">Proceso masivo activo </div>
                                    <div class="mt-2" >
                                        <span class="text-dark" >Lote: {{ response_massive_process[0].lote }} </span>


                                            <div class="small">
                                                <i class="fas fa-exclamation-triangle text-danger"></i> Usted posee un proceso de pago masivo en proceso para esta orden de pago. Por favor, no realice ningún pago para evitar duplicidad de pagos. Asegúrese de tener fondos disponibles en su cuenta emisora para realizar el pago automático sin contratiempos.
                                            </div>

                                    </div>
                                </div>




                                <div class="alert border-1 border-warning bg-white mt-3 text-secondary" role="alert" *ngIf="duplicates[item.code]?.warning?.length > 0" >
                                    <div class="fw-bold text-warning">Ordenes similares </div>
                                    <div class="mt-2" *ngFor="let item of duplicates[item.code]?.warning">
                                        <span class="text-dark" >OP{{item?.invoiceNumber}} </span>

                                        <span [ngClass]="{'text-success': item?.payment_status?.code != 'PaymentPending'}">{{item?.payment_status?.name}}</span>
                                            <div class="small">
                                                {{item.description}}
                                            </div>

                                    </div>
                                </div>

                                    <div class="alert border-1 border-danger bg-white mt-3 text-secondary" role="alert" *ngIf="duplicates[item.code]?.danger?.length > 0" >
                                        <div class="fw-bold text-danger">
                                            <span *ngIf="item?.payment_status?.code == 'PaymentTrash'"> Oden creada</span>
                                            <span *ngIf="item?.payment_status?.code != 'PaymentTrash'"> Oden duplicada</span>

                                            </div>
                                        <div class="mt-2" *ngFor="let item of duplicates[item.code]?.danger">
                                            <span class="text-dark" >OP{{item?.invoiceNumber}} </span>
                                            <span [ngClass]="{'text-success': item?.payment_status?.code != 'PaymentPending'}">{{item?.payment_status?.name}}</span>
                                            <div class="small">
                                                {{item.description}}
                                            </div>
                                        </div>
                                     </div>


                                <div class="col-12">
                                    <div class="small text-secondary ">Tipo de Orden</div>
                                    <div class=" text-dark"> {{invoice_types_code_description_obj[item?.params?.invoice_type?.code]?.name }}</div>
                                </div>

                                <hr>

                                <div class="row mt-2  row-k">
                                    <div class="col-12 col-md-6 mb-3">
                                        <div class=" text-secondary">Emisor - {{invoice_types_code_description_obj[item?.params?.invoice_type?.code]?.from}}</div>
                                        <h5 class="fw-bolder text-dark"> {{item?.customer_suscriptor?.name }} <div class="fw-normal small">{{ item?.identity_suscriptor?.code_identity?.code}}-{{ item?.identity_suscriptor?. identity}}</div> </h5>
                                    </div>


                                    <div class="col-12 col-md-6  mb-4 text-md-end ">
                                        <div class="small text-secondary ">Cuenta Bancaria ({{invoice_types_code_description_obj[item?.params?.invoice_type?.code]?.from}})</div>


                                        <div *ngIf="item?.client_suscriptor">

                                            <div class=" text-dark fw-bold" >

                                                {{item?.client_suscriptor?.payment_method?.name }}

                                            </div>

                                            <div class=" text-dark" *ngIf="item?.client_suscriptor?.identity" >

                                                {{item?.client_suscriptor.identity }}

                                            </div>

                                            <div class=" text-dark " >

                                                {{item?.client_suscriptor?.bank?.name }}





                                            </div>

                                            <div class=" text-dark " *ngIf="item?.client_suscriptor?.bank" >

                                                {{ item?.client_suscriptor?.account_type==="00"?"Corriente": "Ahorro" }} ({{ item?.client_suscriptor?.currency?.name }}),

                                            </div>


                                            <div class=" text-dark " >

                                                {{ item?.client_suscriptor?.account_number }}

                                            </div>


                                            <div *ngIf="item?.client_suscriptor?.name_contract">
                                                {{ item?.client_suscriptor?.name_contract }}
                                            </div>



                                            <div *ngIf="item?.client_suscriptor?.number_contrac">
                                                {{ item?.client_suscriptor?.number_contrac }}
                                            </div>



                                        </div>


                                        <div *ngIf="!item?.client_suscriptor"> - </div>
                                    </div>



                                    <div class="col-12 col-md-6  mb-3 ">
                                        <div class=" text-secondary">Receptor - {{invoice_types_code_description_obj[item?.params?.invoice_type?.code]?.to}}</div>
                                        <h5 class="fw-bolder text-dark"> {{item?.customer?.name }} {{item?.customer?.lastName }}<div class="fw-normal small">{{ item?.identity?.code_identity?.code}}-{{ item?.identity?. identity}}</div> </h5>

                                    </div>


                                    <div class="col-12 col-md-6  mb-4 text-end text-md-end ">
                                        <div class="small text-secondary ">Cuenta Bancaria ({{invoice_types_code_description_obj[item?.params?.invoice_type?.code]?.to}})</div>


                                        <div *ngIf="item?.client_payment">

                                            <div class=" text-dark fw-bold" >

                                                {{item?.client_payment?.payment_method?.name }}

                                            </div>

                                            <div class=" text-dark" *ngIf="item?.client_payment?.identity" >

                                                {{item?.client_payment.identity }}

                                            </div>



                                            <div class=" text-dark " >

                                                {{item?.client_payment?.bank?.name }}

                                            </div>

                                            <div class=" text-dark " *ngIf="item?.client_payment?.bank" >

                                                {{ item?.client_payment?.account_type==="00"?"Corriente": "Ahorro" }} ({{ item?.client_payment?.currency?.name }}),

                                            </div>


                                            <div class=" text-dark " >


                                            <span class=" text-dark " >

                                                {{ item?.client_payment?.account_number }}


                                            </span>

                                            <span class=" text-dark " >

                                                {{item?.client_payment?.phone }}

                                            </span>

                                            </div>

                                            <div *ngIf="item?.client_payment?.name_contract">
                                                {{ item?.client_payment?.name_contract }}
                                            </div>




                                            <div *ngIf="item?.client_payment?.number_contrac">
                                                {{ item?.client_payment?.number_contrac }}
                                            </div>



                                            <div *ngIf="item?.params?.client_payment_code_dynamic">
                                                {{ item?.params?.client_payment_code_dynamic }}
                                            </div>



                                        </div>


                                        <div *ngIf="!item?.client_payment"> - </div>

                                    </div>


                                    <hr>

                                    <div class="col-6">
                                        <div class="small text-secondary">ID Transaccion</div>
                                        <div class=" text-dark"> {{item?.identifier }}</div>
                                    </div>


                                    <div class="col-6 text-end ">

                                        <div class="small text-secondary">Fecha de creacción</div>
                                        <div class=" text-dark"> {{item?.date | date: 'dd/MM/yyyy'}}</div>

                                    </div>


                                    <div class="col-6 ">
                                        <div class="small text-secondary ">Tipo de documento</div>
                                        <div class=" text-dark">{{item?.params?.type_invoice}}</div>
                                    </div>





                                    <div class="col-6 text-end ">
                                        <div class="small text-secondary ">Código documento</div>
                                        <div class=" text-dark"> {{item?.merchantNumber }}</div>
                                    </div>



                                    <div class="col-12" *ngIf="item?.currency.code != 'USD'">
                                        <div class="small text-secondary ">Factura indexada </div>
                                        <div class=" text-dark">{{item?.params?.indexed?'Si':'No'}}</div>
                                    </div>




<!--
                                    

<div class="col-6">
                                        <div class="small text-secondary">Plan</div>
                                        <div class="text-dark">{{item?.plan?.name}} </div>
                                    </div>-->

                                    <div class="col-6">
                                        <div class="small text-secondary">Tienda</div>
                                        <div class="text-dark">{{item?.params?.shop?item?.params?.shop?.name:'-' }} </div>
                                    </div>



                                    <div class="col-6  text-end ">
                                        <div class="small text-secondary">Estatus</div>
                                        <div [ngClass]="{
                                        'text-success': item?.payment_status?.code == 'PaymentComplete',
                                        'text-dark': item?.payment_status?.code == 'PaymentPending',
                                        'text-warning': item?.payment_status?.code == 'PaymentTrash'
                                    }" class="text-dark" [title]="item?.installMent ?.payment_status?.description">{{item?.payment_status?.code == 'PaymentTrash'? 'Anulado':item?.payment_status?.name }} </div>
                                    </div>


                                    <div class="col-6  ">
                                        <div class="small text-secondary">Fecha de Emisión</div>
                                        <div class=" text-dark"> {{item?.params ?.femicion | date: 'dd/MM/yyyy'}}</div>

                                    </div>


                                    <div class="col-6 text-end  ">

                                        <div class="small text-secondary">Fecha de vencimiento</div>
                                        <div class=" text-dark"> {{item?.params ?.fvencimiento | date: 'dd/MM/yyyy'}}</div>

                                    </div>


                                    </div>

                                    <div class="row">


                                    <div class="col-6 mt-3 ">

                                        <div class="small text-secondary">Monto total de la orden</div>
                                        <div class=" text-dark">{{item?.counterValue | currency:'USD':'': '1.2-2'}} <span class="small">{{item?.currency?.name}}</span></div>

                                    </div>


                                    <div class="col-6 text-end  mt-3">
                                        <div class="small text-secondary">Monto total en (USD) </div>
                                        <div class=" text-dark">{{item?.amount | currency:'USD':'': '1.2-2'}} <span class="small">USD</span></div>
                                    </div>

                                    </div>
                                    <div class="row">


                                    <div class="form-group  col-6  mt-3">
                                        <label for="chage_rate" class=" label font-weight-normal text-secondary small" style="word-wrap: break-word;">
                                        <span >Tasa de cambio </span>
                                    </label>
                                        <div class=" text-dark"> USD 1 = BS {{item.rate | currency:'USD':'': '1.2-2'}}</div>
                                    </div>


                                    <div class="form-group  col-6   mt-3  text-end  ">
                                        <label for="chage_rate" class=" label font-weight-normal text-secondary small" style="word-wrap: break-word;">
                                        <span > Creador </span>
                                    </label>
                                        <div class=" text-dark"> {{item?.user?.name}} {{item?.user?.lastName}}</div>
                                    </div>
                                </div>
                                <div class="row">

                                    <div class="col-6  mt-3"  >
                                        <div class="small text-secondary">Tipo de gasto</div>
                                        <div class=" text-dark">{{type_expenses_obj[item.params.type_expense] || '-'}}</div>
                                    </div>


                                    <div class="col-6  mt-3  text-end"  >
                                        <div class="small text-secondary">Tipo de transferencia</div>
                                        <div class=" text-dark">{{item?.params?.type_transf  || '-'}}</div>
                                    </div>


                                    <div class="col-6  mt-3 "  >
                                        <div class="small text-secondary">Prioridad</div>
                                        <div  [ngClass]="{'text-secondary': item?.params?.priority?.current?.code == 1 , 'text-warning': item?.params?.priority?.current?.code == 2,'text-danger': item?.params?.priority?.current?.code == 3}">{{prioritis[item?.params?.priority?.current?.code]  || '-'}}</div>
                                    </div>


                                    <div class="col-6  mt-3  text-end"  >
                                        <div class="small text-secondary">Resp. prioridad</div>
                                        <div class=" text-dark">{{item?.params?.priority?.current?.ejecutive?.name  || '-'}}</div>
                                    </div>


                                    <div class="col-12  mt-3 "  *ngIf="item?.params?.exchange_rate_adjustment?.status" >
                                        <div class="small text-secondary">Diferencial Cambiario</div>
                                        <div class="small text-dark"> USD {{item?.params?.exchange_rate_adjustment?.usd?.amount || 0 | currency:'USD':'': '1.2-2'}} </div>
                                        <div class=" small text-dark"> BS {{item?.params?.exchange_rate_adjustment?.counter?.amount ||0 | currency:'USD':'': '1.2-2'}} </div>


                                    </div>



                                    <div class="col-12   mt-3" >
                                        <div class="small text-secondary">Observación de la prioridad </div>
                                        <div class=" text-dark">{{item.params?.priority?.current?.description  || '-'}}</div>
                                    </div>

                                    <div class="col-12   mt-3" *ngIf="item?.description">
                                        <div class="small text-secondary">Descripcion de la órden </div>
                                        <div class=" text-dark">{{item?.description}}</div>
                                    </div>

                                    <div class=" col-12 fw-bold text-secondary mt-4 " *ngIf="item?.invoice_file > 1">Adjuntos</div>


                                    <div class="col-12 ">

                                        <div class="upload-table mt-3 w-100" *ngIf="item?.invoice_file">
                                            <table class="table table-bordered">

                                                <tbody class="upload-name-style">
                                                    <tr *ngFor="let item of item?.invoice_file; let i = index">
                                                        <td><strong>{{ item?.base_name }}</strong></td>
                                                        <td class="text-center">
                                                            <button type="button" class="btn btn-link text-primary btn-sm" (click)="redirectIMG(item?.url)">Ver</button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                    </div>


                                    <ng-container  *ngIf="_auth.IsPermitid(['OWNER', 'PAYMENT_EXECUTION']) && item?.payment_status?.code != 'PaymentComplete' " >


                                    <div class="form-group  col-12 mt-3" >

                                        <div>
                                            <div class=" mt-3 fw-bold">Tiendas</div>
                                            <mbsc-select
                                              id="mySelect"
                                              [(ngModel)]="shop"
                                              [filter]="true"
                                              [data]="options2"
                                              display="bubble"
                                              placeholder="Seleccione una opción"
                                              valueField="value"
                                              textField="text"
                                              (onSet)="setTypeShop(item)"
                                              locale="es"
                                              >

                                            </mbsc-select>
                                          </div>


                                    </div>

                                    <div class="form-group  col-12 mt-3" >

                                        <div>
                                            <div class=" mt-3 fw-bold">Tipos de gastos</div>
                                            <mbsc-select
                                              id="mySelect"
                                              [(ngModel)]="item.params.type_expense"
                                              [filter]="true"
                                              [data]="options"
                                              display="bubble"
                                              placeholder="Seleccione una opción"
                                              valueField="value"
                                              textField="text"
                                              (onSet)="setTypeExpense(item)"
                                              locale="es"
                                              >

                                            </mbsc-select>
                                          </div>


                                    </div>

                                    </ng-container>


                                    <ng-container  *ngIf="_auth.IsPermitid(['OWNER', 'REVIEW_AND_APPROVAL_OF_PAYMENT_REQUEST']) && item?.payment_status?.code != 'PaymentComplete' " >


                                <div class=" col-12 fw-bold text-secondary mt-4 ">Prioridad</div>

                                <div class="form-group mt-4"  >
                                    <div class="form-floating">
                                        <select  [(ngModel)]="item.params.priority.current.code" class="form-select" id="floatingSelect" aria-label="Floating label select example">
                                        <option   value="1">Baja</option>
                                        <option   value="2">Media</option>
                                        <option   value="3">Alta</option>


                                        </select>
                                        <label for="floatingSelect">Prioridad</label>
                                    </div>
                                </div>

                                <div class="form-group mt-4" >
                                    <div class="form-floating">
                                        <textarea   [(ngModel)]="item.params.priority.current.description" class="form-control" placeholder="Leave a comment here" id="floatingTextarea2" style="height: 100px"></textarea>
                                        <label for="floatingTextarea2">Observación de la prioridad</label>
                                    </div>
                                </div>

                                <div class="mt-1 text-md-end">
                                    <button type="button" class="btn btn-primary mt-4" (click)="setInvoice_priority(item)"> Actualizar Prioridad </button>

                                </div>

                                    </ng-container>
                                    <!--

                                     <div class="col-12" >

                                        <div *ngIf="pagares[item._id]">

                                            <a class="text-primary cursor-pounter float-start" (click)="downloadFile(pagares[item._id].file.url, 'pdf', item.identifier+'.pdf')">Descargar Pagaré
                                                <span *ngIf="submit_download_disabled">
                                                <i class="fa  fa-spinner fa-spin ms-2"></i>
                                            </span></a>
                                        </div>
                                    </div>
                                 -->

                                </div>

                            </div>

                            <div class="row rmt-2" >
                             <div class="col-12 ">

                                <div *ngFor=" let item2 of item?.installments;let i = index" >

                                    <div class="bg-white mt-4 my-3 p-3 bg-body2  rounded shadow-sm" *ngIf=" item2?.order > 0" >


                                    <div class="row mt-2 row-k" >
                                        <div class="col-6 mt-0">

                                            <h5 class="fw-bolder text-dark"> {{ 'Cuota ' +item2?.order}}</h5>
                                        </div>

                                        <div class="col-6 text-end mt-0">
                                            <div class="small text-secondary">Estatus</div>
                                            <div [ngClass]="{'text-success': item2 ?.payment_status?.code == 'PaymentComplete','text-dark': item2 ?.payment_status?.code != 'PaymentComplete'}" class="text-dark" [title]="item2 ?.payment_status?.description">{{item2 ?.payment_status?.name}} </div>
                                        </div>


                                        <div class="col-6 mt-2">
                                            <div class="small text-secondary">Total</div>
                                            <div class=" text-dark"> {{item2?.amount | currency:'USD':'': '1.2-2'}} USD</div>
                                        </div>

                                        <div class="col-6 mt-2 text-end">
                                            <div class="small text-secondary">Monto faltante</div>
                                            <div class=" text-dark"> {{item2?.dueBalance | currency:'USD':'': '1.2-2'}} USD</div>
                                        </div>


                                        <div class="col-6">
                                            <div class="small text-secondary">Fecha</div>
                                            <div class=" text-dark"> {{item2?.date | date: 'dd/MM/yyyy'}}</div>
                                        </div>


                                        <div class="col-6 text-end">

                                            <div class="small text-secondary">Fecha de vencimiento</div>
                                            <div class=" text-dark"> {{item?.params ?.fvencimiento | date: 'dd/MM/yyyy'}}</div>

                                        </div>


                                        <div class="col-12 pt-3 pb-3" *ngIf="item2?.workflow?.current?.step === 'PAYMENT_EXECUTION' && date_scheduled_payment_current===''">
                                            <div class="">
                                                <div class="">
                                                    <h5 class="fw-bolder text-dark"> Pago másivo </h5>
                                                    <div class="col" *ngIf="item?.client_payment?.payment_method.code!=='CONTRACT_DYNAMIC'">
                                                        <div class="small text-secondary">Fecha programada</div>
                                                        <div class=" text-dark">
                                                            <input class="form-control " type="date" [(ngModel)]="date_scheduled_payment" />
                                                        </div>


                                                    </div>

                                                    <div class="col pt-2" *ngIf="item?.client_payment?.payment_method.code!=='CONTRACT_DYNAMIC'">
                                                        <div class="small text-secondary">Entidad Bancaria</div>
                                                        <div class=" text-dark">
                                                            <select class="form-control ">
                                                                <option value="">Bancamiga</option>

                                                            </select>
                                                        </div>


                                                    </div>
                                                    <div class="col" *ngIf="item?.client_payment?.payment_method.code!=='CONTRACT_DYNAMIC'">

                                                        <div class=" text-dark text-end pt-2">
                                                            <button type="button"  class="btn btn-outline-primary btn-sm" (click)="setPaymentDate(item?._id)">
                                                                Programar
                                                            </button>
                                                        </div>

                                                    </div>


                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-6"*ngIf="date_scheduled_payment_current!==''" >

                                            <div class="small text-secondary">Fecha programada pago másivo</div>
                                            <div class=" text-dark"> {{date_scheduled_payment_current | date: 'dd/MM/yyyy'}} <i class="fas fa-edit text-primary" (click)="editar()" title="Reprogramar" *ngIf="_auth.IsPermitid(['OWNER','PAYMENT_EXECUTION'])"></i> &nbsp;<i class="fas fa-times text-danger" (click)="eliminar(item)" title="Eliminar programación" *ngIf="_auth.IsPermitid(['OWNER','PAYMENT_EXECUTION'])"></i></div>

                                        </div>



                                        <div class="col-12">

                                           <!--  <span class="text-primary cursor-pounter" (click)="getPersonInstallmentsInvoiceCredits(item)" *ngIf="!(installments[item2.code]?.payments?.length > 0 || item?.paymentReference?.length > 0)" >Ver pagos</span>
-->

                                            <div class="row mt-2 row-k">

                                                <div class="fw-bold text-secondary"  >Pagos</div>

                                                <div class="table-responsive " *ngIf="installments[item2.code]?.payments?.length > 0  || installments[item2.code]?.paymentReference?.length > 0 ">
                                                    <table class="table table-bordered table-container ">
                                                        <thead>
                                                            <tr>
                                                                <th>Monto (Bs)</th>
                                                                <th>Monto (USD)</th>

                                                                <th>Referencia</th>
                                                                <th>Estatus</th>

                                                            </tr>
                                                        </thead>
                                                        <tbody>



                                                <ng-container class="mt-3" *ngFor=" let item2 of installments[item2.code]?.paymentReference ;let i = index">


                                                    <ng-container *ngIf="item?.status">

                                                        <tr class=" " >
                                                            <td class="text-primary  cursor-pointer" (click)="openBottom(payment,{ person_code: item?.customer?.code, code_payment: item2?.payment?.code})">{{( item2?.payment?.amount_counter || item2?.payment?.counterValue || 0) | currency:'USD':'': '1.2-2'}} Bs</td>

                                                            <td class="" >{{item2?.payment?.amount | currency:'USD':'': '1.2-2'}} USD</td>


                                                            <td>{{item2?.payment?.paymentMethodId}}</td>

                                                            <td>
                                                                <div class="text-success" *ngIf="item2?.payment?.reconciled_payment && !item2?.payment?.params?.data_is_missing"><span class="badge text-bg-success">Aprobado</span> </div>

                                                                <div *ngIf="!item2?.payment?.reconciled_payment && !item2?.payment?.params?.data_is_missing "><span (click)="redirectTo()" class="badge text-bg-warning">Por conciliar</span>
                                                                </div>


                                                                <div  *ngIf="item2?.payment?.params?.data_is_missing   && !item2?.payment?.params?.sing">
                                                                    <a class="btn btn-outline-primary btn-sm" (click)="singPayment(item2?.payment)">Firmar pago</a>

                                                                </div>

                                                                <div  *ngIf="item2?.payment?.params?.data_is_missing   && item2?.payment?.params?.sing"><span class="badge text-bg-secondary ">Firmado por completar</span> </div>

                                                            </td>
                                                        </tr>



                                                </ng-container>


                                                </ng-container>


                                                <ng-container class="mt-3"  *ngFor=" let item2 of installments[item2.code]?.payments ;let i = index">

                                                    <tr class="small  cursor-pointer" (click)="openBottom(payment,{ person_code: item?.customer?.code, code_payment: item2?.payment?.code})" >
                                                        <td class="text-primary " >{{( item2?.payment?.amount_counter || item2?.payment?.counterValue || 0)  | currency:'USD':'': '1.2-2'}} Bs</td>

                                                        <td class="text-primary  cursor-pointer" >{{item2?.payment?.amount | currency:'USD':'': '1.2-2'}} USD</td>

                                                        <td>{{item2?.payment?.paymentMethodId}}</td>

                                                        <td>
                                                            <div class="text-success" *ngIf="item2?.payment?.reconciled_payment"><span class="badge text-bg-success">Aprobado</span> </div>

                                                            <div *ngIf="!item2?.payment?.reconciled_payment"><span (click)="redirectTo()" class="badge text-bg-warning">Por conciliar</span>
                                                            </div>

                                                        </td>
                                                    </tr>
                                                </ng-container>


                                                <ng-container class="mt-3" *ngFor=" let item2 of installments[item2.code]?.installMentPaymentAnulados ;let i = index">


                                                    <ng-container *ngIf="!item?.status">

                                                        <tr class=" " >
                                                            <td class="text-primary  cursor-pointer" (click)="openBottom(payment,{ person_code: item?.customer?.code, code_payment: item2?.payment?.code})">{{( item2?.payment?.amount_counter || item2?.payment?.counterValue || 0)  | currency:'USD':'': '1.2-2'}} Bs</td>

                                                            <td class="" >{{item2?.payment?.amount | currency:'USD':'': '1.2-2'}} USD</td>


                                                            <td>{{item2?.payment?.paymentMethodId}}</td>

                                                            <td>
                                                                <div class="text-danger"><span class="badge text-bg-danger">Anulado</span> </div>

                                                            </td>
                                                        </tr>



                                                </ng-container>


                                                </ng-container>

                                            </tbody>
                                        </table>
                                    </div>


                                            </div>


                                        </div>





                                        <hr class="mt-3 mb-3">


                                        <div>
                                            <div class="float-end small">

                                                <a class="text-primary cursor-pounter" *ngIf="wfHistory" (click)="wfHistory = false" >Actual</a>
                                                <a class="text-primary cursor-pounter" *ngIf="!wfHistory" (click)="wfHistory = true" >Historial</a>


                                            </div>

                                            <div class="fw-bold ">
                                                <div type="button" class="fw-bold text-dark cursor-pounter " (click)="  pocisionBottom2(content2,item2?.workflow?.current?.step)">Flujo del proceso > </div>

                                            </div>

                                        </div>



                                    <ng-container *ngIf="wfHistory">



                                        <ng-container *ngFor="let item3 of item2?.workflow?.history ; let i=index">





                                        <div class="col-6 ">
                                            <div class="small text-secondary">Fecha</div>
                                            <div class=" text-dark"> {{item3?.date | date: 'dd/MM/yyyy h:mm:ss a'}}</div>

                                        </div>

                                        <div class="col-6 text-end">
                                            <div class="small text-secondary g">Estado</div>
                                            <div class=" fw-bold text-warning"> {{stepsObj[item3?.step]?.name}}</div>

                                        </div>


                                        <div class="col-6">
                                            <div class="small text-secondary">Responsable </div>
                                            <div class="relative inline-block text-left">
                                              <div>
                                                <div class="cursor-pointer" (click)="showPopover(i, item3?.notification?.note?.ejecutive?.person)" (mouseleave)="hidePopover(i)"> {{item3?.ejecutive?.name}} {{item3?.ejecutive?.lastName}}</div>
                                              </div>

                                              <div *ngIf="isPopoverOpen(i)" class="absolute z-10 mt-2 w-64 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                                                <div class="px-3 py-2 bg-gray-100 border-b border-gray-200 rounded-t-lg dark:border-gray-600 dark:bg-gray-700">
                                                  <h3 class="font-semibold text-gray-900 dark:text-white">{{item3?.ejecutive?.name}} {{item3?.ejecutive?.lastName}}</h3>
                                                </div>
                                                <div class="px-3 py-2">
                                                  <div *ngFor="let contact of respon?.contacts">
                                                    <p *ngIf="!loading_contact">{{contact?.contact}}</p>
                                                  </div>
                                                  <i class="fas fa-spinner fa-spin" style="margin-left: 100px;" *ngIf="loading_contact"></i>
                                              </div>
                                              </div>
                                          </div>
                                        </div>

                                        <!-- <pre>{{respon | json}}</pre> -->

                                        <!-- <pre>{{item3?.notification?.note?.ejecutive?.person | json}}</pre> -->

                                        <div class="col-6 text-end">
                                            <div class="small text-secondary">Fecha modificación</div>
                                            <div class=" text-dark"> {{item3?.date_update | date: 'dd/MM/yyyy h:mm:ss a'}}</div>

                                        </div>

                                        <div class="col-6 ">
                                            <div class="small text-secondary">Acción</div>
                                            <div class=" text-dark"> {{item3?.name_action}}</div>

                                        </div>
   <!--
                                        <div class="col-12">
                                            <div class="small text-secondary">Descripción</div>
                                            <div class=" text-dark"  [innerHTML]="gionOFbr(stepsObj[item?.workflow?.current?.step]?.description)"> </div>

                                        </div>
-->
                                        <div class="col-12" *ngIf="item3?.notification?.note">

                                            <div class="small text-secondary">Nota</div>
                                            <div class=" text-dark"> {{item3?.notification?.note?.note }}</div>

                                        </div>




                                        <hr style="border: #cccccce3 1px solid;" class="mt-3 mb-3">



                                        </ng-container>

                                    </ng-container>



                                    <ng-container *ngIf="!wfHistory && stepsObj[item2?.workflow?.current?.step]">

                                        <div class="col-6">
                                            <div class="small text-secondary">Fecha</div>
                                            <div class=" text-dark"> {{item2?.workflow?.current?.date | date: 'dd/MM/yyyy h:mm:ss a'}}</div>

                                        </div>


                                        <div class="col-6  text-end">
                                            <div class="small text-secondary">Estado</div>
                                            <div class=" fw-bold text-warning"> {{stepsObj[item2?.workflow?.current?.step]?.name}}</div>

                                        </div>
                                        <!-- <div class="col-6 ">
                                            <div class="small text-secondary">Fecha programada</div>
                                            <div class=" text-dark"> {{item?.date_schedule_execution | date: 'dd/MM/yyyy'}}</div>

                                        </div> -->

                                    </ng-container>

                                    <div class="col-12" *ngIf="!loading_person && !loading">

                                        <div>
                                            <ng-container *ngTemplateOutlet="actionwf; context: { $implicit: {item,item2} }"></ng-container>
                                          </div>


                                    </div>
                                    </div>

                                    </div>

                                </div>


                            </div>

                        </div>



                        </ng-container>
                    </div>

                </div>


                <div class="row rmt-2" *ngIf="view == 3">
                    <div class="col-12  col-md-10 offset-md-1">
                        <div *ngIf="installments" class="text-center">
                            <div class="text-secondary">---</div>
                            <div class="small text-secondary mt-2">ID Transaccion</div>
                            <div class=" text-primary"> {{invoiceSelected?.identifier }}</div>

                            <div class="fw-bold"> Pago {{ installMentSelected?.order
                                < 1 ? 'Inicial': 'Cuota '+ installMentSelected?.order }} </div>

                            </div>



                            <div class="bg-white mt-4 my-3 p-3 bg-body2  rounded shadow-sm" *ngFor="let payment of payment_base">

                                <div class="row mt-2 row-k">
                                    <div class="col-6 mt-2">
                                        <h5 class="fw-bolder text-dark"> Pago</h5>
                                    </div>
                                    <div class="col-8 mt-2  ">
                                        <div class="small text-secondary">Código</div>
                                        <div class=" text-dark small"> {{payment?.payment?.code}}</div>
                                    </div>
                                    <div class="col-4   ">

                                        <div class="text-end ms-auto">
                                            <div class="small text-secondary">Conciliación</div>

                                            <div class=" small text-success" [ngClass]="{'text-success': payment?.payment?.typeTransaction == 'AUTO','text-secondary': payment?.payment?.typeTransaction != 'AUTO'}"> {{payment?.payment?.typeTransaction == 'AUTO'? 'Auto':'Manual'}}</div>
                                        </div>
                                    </div>

                                    <div class="col-6">
                                        <div class="small text-secondary">Monto total</div>
                                        <div class=" text-dark"> {{payment?.payment?.amount | currency:'USD':'': '1.2-2'}} USD</div>
                                    </div>
                                    <div class="col-6 text-end">
                                        <div class="small text-secondary">Estatus</div>
                                        <div class="text-success">Pago conciliado </div>
                                    </div>
                                    <div class="col-12 ">
                                        <div class="small text-secondary">Fecha</div>
                                        <div class=" text-dark"> {{payment?.payment?.date | date: 'dd/MM/yyyy h:mm:ss a'}}</div>

                                    </div>

                                    <div class="col-6 ">
                                        <div class="small text-secondary">Forma de Pago</div>
                                        <div class=" text-dark" [title]="payment?.payment?.payment_method?.description"> {{payment?.payment?.payment_method?.name}}</div>

                                    </div>
                                    <div class="col-6  text-end">
                                        <div class="small text-secondary">Número de confirmación</div>
                                        <div class=" text-dark"> {{payment?.payment?.paymentMethodId}}</div>
                                    </div>

                                </div>


                                <div class="fw-bold text-secondary mt-3 mb-2" *ngIf="payment?.payment?.responseAutoConfirm?.voucher">Voucher</div>


                                <div class="text-dark  fw-bold fz-14 mb-2" *ngIf="payment?.payment?.responseAutoConfirm?.descripcion">
                                    {{payment?.payment?.responseAutoConfirm?.descripcion}}
                                </div>

                                <div class="row" *ngIf="payment?.payment?.responseAutoConfirm?.voucher">
                                    <div class="col-12 col-md-6">
                                        <div class="voucher p-2 text-dark" [innerHTML]="payment?.payment?.responseAutoConfirm?.voucher">

                                        </div>

                                    </div>
                                </div>

                                <div class="row mt-3 row-k" *ngIf="payment?.installMentsPayment?.length > 0">


                                    <div class="fw-bold text-secondary col-12 mt-2">Distribución</div>
                                    <ng-container *ngFor=" let item of payment?.installMentsPayment ;let i = index">
                                        <div class="col-8">
                                            <div class="small text-primary">{{ item?.installMent?.order
                                                < 1 ? 'Inicial': 'Cuota '+ item?.installMent?.order }} </div>
                                                    <div class="small text-secondary small">{{item?.installMent?.code }}</div>

                                            </div>
                                            <div class="col-4 text-end ">
                                                <div class=" text-dark"> {{item?.amount | currency:'USD':'': '1.2-2'}} USD</div>
                                            </div>

                                    </ng-container>

                                    </div>

                                    <!-- <pre>{{ payment?.paymen_categ_concept_deduc | json }}</pre> -->

                                    <div class="row row-k" *ngIf="payment?.paymen_categ_concept_deduc?.length > 0">

                                        <ng-container *ngFor=" let item of payment?.paymen_categ_concept_deduc ;let i = index">
                                            <div class="col-8" *ngIf="item?.category_concept_deduction?.concept_deduction?.name == 'IGTF'">
                                                <div class="small text-primary">{{item?.category_concept_deduction?.concept_deduction?.name}}
                                                    <span class="text-secondary" *ngIf="item?.category_concept_deduction.isPorcent">{{item?.category_concept_deduction?.amount}}%</span> </div>
                                            </div>
                                            <div class="col-4 text-end " *ngIf="item?.category_concept_deduction?.concept_deduction?.name  == 'IGTF'">
                                                <div class=" text-dark"> {{item?.amount | currency:'USD':'': '1.2-2'}} USD</div>
                                            </div>

                                        </ng-container>

                                    </div>

                                </div>


                            </div>

                        </div>


            </ng-container>


            <div *ngIf="!loading && !loading_person &&  person?.person && resp_invoiceCredits?.length < 1" class="col-12  col-md-10 offset-md-1">
                <div class="mt-5 my-3 p-3 bg-body rounded shadow-sm">
                    <div>No se encontraron Compras financiadas</div>
                </div>
            </div>

            <div *ngIf="!loading_person && !person?.person">
                <div>No se encontro person con este id: {{queryParams?.code}}</div>
            </div>


                <ng-container *ngIf="loading_person || loading">
                    <div class="w-100 text-center mt-2">
                        <img src="./assets/public/images/loading.gif  " alt=" ">
                    </div>
                </ng-container>


            </div>
            </div>

        </div>



        <ng-template #payment let-offcanvas>
            <div class="offcanvas-header">
                <div class="container ">
                    <div class="row">
                        <div class="col-12 col-md-10 offset-md-1 ">
                            <div class="row">
                                <div class="col-10">
                                    <div class="text-capitalize h5 text-secondary mt-2 ">
                                       Detalle de Pago
                                    </div>
                                </div>
                                <div class="col-2 text-end pt-2">
                                    <button type="button" class="btn-close" aria-label="Close" (click)="offcanvas.dismiss('Cross click'); "></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="offcanvas-body">
                <div class=" container">
                    <div class="row">
                        <div class=" col-12 col-md-10 offset-md-1">
                            <div class="text-start">


                                <app-person-payments-orders [code_person]="params_payment['person_code']" [code_payment]="params_payment['code_payment']" (resetStatus)="response($event)"></app-person-payments-orders>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </ng-template>
