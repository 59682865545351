import { DatePipe } from '@angular/common';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CommonService } from 'src/app/services/common.service';
import { RedirecFormService } from 'src/app/services/redirec-form.service';
import { SocketioService } from 'src/app/services/socket/socketio.service';
import { UserService } from 'src/app/services/user/user.service';
import { mobiscroll, MbscDatetimeOptions, MbscFormOptions } from '@mobiscroll/angular';
import { debounceTime } from 'rxjs/operators';

mobiscroll.settings = {
  lang: 'es',
  theme: 'ios',
  themeVariant: 'light',
};
const now = new Date().setHours(0,0,0,0);
let week = [ new Date().setHours(0,0,0,0) ,new Date().setHours(23,59,59,999)];



@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.css'],
  providers: [DatePipe]

})
export class InvoicesComponent implements OnInit {
  loading:any = true;
  invoices:any = [];
  timeoutId: any;

  params_invoice:any;
  sort_filter:any ='dateEnd';

  orders:any = [];
  columns:any = [];
  rows:any = [];
  options2:any = [{value:'',text:'Todos'}];



  options:any = [{value:'',text:'Todos'}];

  rows_base:any = [];
  prioritis:any = {
    "1":"Baja",
    "2":"Media",
    "3":"Alta"
  };


  steps: any =[];
  stepsObj: any ={};

  // filtros
  selectedResponsable: string = '';
  selectedStatus: string = '';
  //selectedRange: Array < Date > = week;
  selectedRange: any;
  selectedOrden: any;

  filters = {};

  banks :any = [];
  bank_filter :any = '';
  emisor_filter :any = '';
  receptor_filter :any = '';


  dateSetting: MbscDatetimeOptions = {
    lang: 'es',
    theme: 'ios',
    themeVariant: 'light',
    responsive: {
      small: {
        controls: ['calendar'],
        display: 'center'
      },
      medium: {
        display: 'bubble',
      }
    }

  };

  limit = 100;
  page = 0;
  totalRows: any = null;
  totalPages: any = null;
  currentPage: any;
  showOld = false

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public _user: UserService,
    private sanitizer: DomSanitizer,
    private redirecFormService: RedirecFormService,
    private datePipe: DatePipe,
    public socketService: SocketioService,
    private commonService: CommonService,
    private offcanvasService: NgbOffcanvas,

    public _auth : AuthService) { }

  async ngOnInit() {
    this.getSelectsBase();

    this.rows = [ ];

    // Definición de columnas
    this.columns = [

      { prop: '#', name: '#', minWidth: 20, width: 20 },
      { prop: '-', name: 'Revision', width: 50 },
      { prop: 'orden', name: 'Orden', width: 100 },
      { prop: 'organizacion', name: 'Organizacion', minWidth: 200},
      { prop: 'proveedor', name: 'Proveedor', minWidth: 200},
      { prop: 'creacion', name: 'creacion', width: 100},
      { prop: 'fecha_emision', name: 'Fecha fecha_emision', width: 100},

      { prop: 'fecha_vencimiento', name: 'Fecha vencimiento', width: 100},
      { prop: 'vencimiento', name: 'Vencimiento', width: 100},
      { prop: 'prioridad', name: 'prioridad', width: 100},


      // { prop: 'hora', name: 'Hora', width: 120 },
      { prop: 'moneda', name: 'Moneda',width: 50 },
      { prop: 'monto', name: 'Monto', minWidth: 60 }, // Ajusta el ancho aquí
      { prop: 'banco_emisor', name: 'Banco Emisor', width: 150},
      { prop: 'etapa', name: 'etapa', minWidth: 200 },

      { prop: 'estatus', name: 'Estatus', minWidth: 150 },


    ];


    // let query = {};
    // // en este caso solo se muestran las ordenes cargadas por ellos
    // if(this._auth.IsPermitid(['PAYMENT_REQUEST'])) {
    //   query = {user: this._auth.sessionUser.uid};
    // }
    // const invoice_resp =  await  this._user.getInvoiceCreditsDashboardPost(query);


    // this.invoices = this.rows_base = this.invoicesList(invoice_resp?.response.result);
    this.socketService.reload_order.subscribe((resp: any) => {
      this.listClientInvoiceCredits();


    });

  }

  invoicesList = (invoices: any) => {
    let rows: any[] = [];

    let i = 1;
    invoices.forEach((item: any) => {

      let obj = item?.params;
      const fechaHoraLocal = new Date(obj?.date);
      const fechaHoraLocal2 = new Date(obj?.params?.fvencimiento);
      const fechaHoraLocal3 = new Date(obj?.params?.femicion);



      const fechaCompleta = fechaHoraLocal.toLocaleString('es-VE', {timeZone: 'America/Caracas'});
      const fechaCompleta2 = fechaHoraLocal2.toLocaleString('es-VE', {timeZone: 'America/Caracas'});
      const fechaCompleta3 = fechaHoraLocal3.toLocaleString('es-VE', {timeZone: 'America/Caracas'});



      // Restar 4 horas para ajustar a Venezuela
      //fechaHoraLocal.setHours(fechaHoraLocal.getHours() - 4);

      // Formatear la nueva fecha y hora en formato legible




      const horaCompleta = fechaCompleta.split(" ")[1].split(":").slice(0, 2).join(":");
      const hora = horaCompleta.length === 5 ? horaCompleta + " AM" : horaCompleta.slice(0, 5) + " PM";

      const fechaArr = fechaCompleta.split(",")[0].split("/");

      const dia = fechaArr[0].padStart(2, '0'); // Agrega ceros a la izquierda si es necesario
      const mes = fechaArr[1].padStart(2, '0'); // Agrega ceros a la izquierda si es necesario
      const anio = fechaArr[2];
      const fechaFormatoDMY = dia + "-" + mes + "-" + anio;


      const fechaArr2 = fechaCompleta2.split(",")[0].split("/");

      const dia2 = fechaArr2[0].padStart(2, '0'); // Agrega ceros a la izquierda si es necesario
      const mes2 = fechaArr2[1].padStart(2, '0'); // Agrega ceros a la izquierda si es necesario
      const anio2 = fechaArr2[2];
      const fechaFormatoDMY2 = dia2 + "-" + mes2 + "-" + anio2;


      const fechaArr3 = fechaCompleta3.split(",")[0].split("/");

      const dia3 = fechaArr3[0].padStart(2, '0'); // Agrega ceros a la izquierda si es necesario
      const mes3 = fechaArr3[1].padStart(2, '0'); // Agrega ceros a la izquierda si es necesario
      const anio3 = fechaArr3[2];
      const fechaFormatoDMY3 = dia3 + "-" + mes3 + "-" + anio3;



      obj?.installments?.forEach((obj2: any) => {
        if (obj2?.order > 0) {


          let organ = '';
          if( obj.customer_suscriptor){
            organ = obj?.customer_suscriptor?.audience == 'LEGAL' ? obj.customer_suscriptor?.name : `${obj?.customer_suscriptor?.name} ${obj?.customer_suscriptor?.lastName || ''}`;

          }

          let povid = obj?.customer?.audience == 'LEGAL' ? obj.customer?.name : `${obj?.customer?.name} ${obj?.customer?.lastName || ''}`;

          let prioridad  = {
            name: this.prioritis['1'],
            code:1
          }


          if(obj.params?.priority?.current?.code){

            prioridad =
              {
                name: this.prioritis[`${obj.params?.priority?.current?.code}`],
                code:obj.params?.priority?.current?.code
              }

          }


          let temp =
            {
              '#': i,
              orden:  {order:`OP${obj?.invoiceNumber}-${obj2?.order}`, code_invoice:obj?.code,code_installment:obj2?.code,code_person:obj?.customer?.code} ,
              //  archivos: obj?.invoice_file ? `${obj?.invoice_file?.length}` : '',
              "-": obj2?.workflow?.user_fullname?true:false,
              //  note: '',
              hora: hora,
              creacion: fechaFormatoDMY,
              fecha_emision: fechaFormatoDMY3,
              fecha_vencimiento: fechaFormatoDMY2,
              vencimiento: {v:fechaFormatoDMY2,s:obj?.payment_status},
              prioridad:  prioridad ,
              proveedor: {name:povid,code:obj.customer?.code} ,

              organizacion: {name:organ,code:obj.customer_suscriptor?.code} ,

              moneda: 'USD',
              etapa:  {code: this.stepsObj[obj2?.workflow?.step]?.code?this.stepsObj[obj2?.step]?.code: "Finalizado", name: this.stepsObj[obj2?.workflow?.step]?.name},

              // responsable:  this.stepsObj[obj2?.workflow?.current?.step]?.name,
              monto: obj2?.amount,
              estatus:obj?.payment_status,
              banco_emisor:obj?.bank_suscriptor?.bank,

              //audience:obj



            };


          rows.push(temp);
          i++;
        }
      });
    });
    this.loading = false;
    return rows;
  }



  generatePages(totalPages: number): number[] {
    const halfPage = Math.floor(this.currentPage / this.limit);
    const startPage = Math.max(halfPage - 2, 1);
    const endPage = Math.min(halfPage + 3, totalPages);

    return Array.from({length: endPage - startPage + 1}, (_, i) => startPage + i);
  }

  async listClientInvoiceCredits(page = 0) {
    try {

      this.loading = true;

      // let start = page * this.limit;
      // let end = start + this.limit;

      let query = {
        "page": page+1,
        "limit": this.limit,
        "invoiceName": "PAYMENT_ORDER"
      };

      // en este caso solo se muestran las ordenes cargadas por ellos
      if(this._auth.IsPermitid(['PAYMENT_REQUEST'])) {
        query = Object.assign(query, {user: this._auth.sessionUser.uid});
      }
      Object.assign(this.filters, {sort: this.sort_filter});

      if(this.filters) {
        query = Object.assign(query, this.filters);

      }


      //const invoice_resp =  await  this._user.getInvoiceCreditsDashboardPost(query);4

      const invoice_resp =  await  this._user.getInvoiceCreditsDashboard_dinamicPost(query);

      this.invoices = this.rows_base = this.invoicesList(invoice_resp?.result);

      let totalRows = invoice_resp?.count;

      this.totalPages = Math.ceil((invoice_resp?.count/this.limit));

      let rows = this.invoices;






      // Calcular el total de páginas
      const totalPages = Math.ceil(totalRows / this.limit);

      this.rows = rows;
      this.totalRows = totalRows;
      this.totalPages = totalPages;
      this.currentPage = this.page ;


      this.loading = false;

    } catch (error) {
      this.loading = false;

      console.error(error);


    }
  }

  changePage = (page: any) => {
    console.log(page, this.totalPages);
    if(page>=0 && page<this.totalPages) {
      this.page = page;
      this.listClientInvoiceCredits(page);

    }
  }

  responder = async (code:any) => {


  }


  getSteps = async () => {


    try {

      const  steps  = await this._user.getSteps({});

      this.steps = steps?.resp;

      for (const item of this.steps) {

        this.stepsObj[item.code] = item;
        console.log(this.stepsObj);

      }


    } catch (error) {
      console.error(error);


    }

  }


  setResetStatus(data:any){

    this.listClientInvoiceCredits();

  }


  pocisionBottom3= async (content: TemplateRef<any>,params_invoice:any) => {
    try {


      this.params_invoice = params_invoice;

      this.offcanvasService.open(content, {
        position: 'bottom',
        keyboard: false,

      });


    } catch (error) {
      console.log(error);
    }
  };


// filter = () => {


//       this.filters = {};
//       if(this.selectedResponsable!=='') {
//         Object.assign(this.filters, {step: this.selectedStatus});
//       }

//       if(this.selectedStatus!=='') {
//         Object.assign(this.filters, {invoiceStatus: this.selectedStatus});
//       }

//       if(this.selectedRange!==null && this.selectedRange!==undefined) {
//         let from = new Date(this.selectedRange[0]);
//         from.setHours(0, 0, 0, 0);

//         let to = new Date(this.selectedRange[1]);
//         to.setHours(23, 59, 59, 999);

//         Object.assign(this.filters, {initDate: from, endDate: to});
//       }

//       if(this.selectedOrden!==null && this.selectedOrden!==undefined && this.selectedOrden!=='') {
//         Object.assign(this.filters, {invoiceNumber: this.selectedOrden});
//       }

//       this.page=0;
//       this.listClientInvoiceCredits();





// }

  filter = () => {
    clearTimeout(this.timeoutId);
    this.timeoutId = setTimeout(() => {
      this.filters = {};

      if (this.selectedResponsable !== '') {
        Object.assign(this.filters, {step: this.selectedResponsable});
      }

      if (this.selectedStatus !== '') {
        Object.assign(this.filters, {invoiceStatus: this.selectedStatus});
      }


      if (this.bank_filter !== '') {
        Object.assign(this.filters, {bank_receptor: this.bank_filter});
      }


      if (this.sort_filter !== '') {
        Object.assign(this.filters, {sort: this.sort_filter});
      }


      if (this.emisor_filter !== '') {
        Object.assign(this.filters, {emisor: this.emisor_filter});
      }

      if (this.receptor_filter !== '') {
        Object.assign(this.filters, {receptor: this.receptor_filter});
      }


      if (this.selectedRange !== null && this.selectedRange !== undefined) {
        let from = new Date(this.selectedRange[0]);
        from.setHours(0, 0, 0, 0);

        let to = new Date(this.selectedRange[1]);
        to.setHours(23, 59, 59, 999);

        Object.assign(this.filters, {initDate: from, endDate: to});
      }

      if (this.selectedOrden !== null && this.selectedOrden !== undefined && this.selectedOrden !== '') {
        Object.assign(this.filters, {invoiceNumber: this.selectedOrden});
      }

      this.page = 0;
      this.listClientInvoiceCredits();
    }, 1000);


  };



  // filter = () => {
  //   let current_rows_base = this.rows_base;
  //   if(this.selectedResponsable!=='') {
  //     current_rows_base = current_rows_base.filter((row: any) => row.responsable.code === this.selectedResponsable);
  //   }
  //   if(this.selectedStatus!=='') {
  //     current_rows_base = current_rows_base.filter((row: any) => row.estatus.name === this.selectedStatus);
  //   }

  //   if(this.selectedRange!==null && this.selectedRange!==undefined) {
  //     let from = new Date(this.selectedRange[0]);
  //     from.setHours(0, 0, 0, 0);

  //     let to = new Date(this.selectedRange[1]);
  //     to.setHours(23, 59, 59, 999);
  //     current_rows_base = current_rows_base.filter((row: any) => {
  //       let rowDate = this.convertDateFormat(row.fecha);
  //       return rowDate.getTime() >= from.getTime() && rowDate.getTime() <= to.getTime();
  //     });
  //   }

  //   if(this.selectedOrden!==null && this.selectedOrden!==undefined && this.selectedOrden!=='') {
  //     console.log(current_rows_base);

  //     current_rows_base = current_rows_base.filter((row: any) => {
  //       return row?.orden?.order?.toLowerCase().includes(this.selectedOrden.toLowerCase());
  //     });
  //   }

  //   this.invoices = current_rows_base;
  //   this.page=0;
  //   this.listClientInvoiceCredits();

  // }

  onChangeLimit = (event: any) => {
    const selectedPage = parseInt((event.target as HTMLSelectElement).value);
    this.limit = selectedPage;
    this.listClientInvoiceCredits();
  }

  convertDateFormat(input: string): any {
    const parts = input.split('-');
    return new Date(parseInt(parts[2].toString()), parseInt(parts[1])-1, parseInt(parts[0]));
  }

  clearRange() {
    this.loading = true;
    this.selectedRange = null;
    this.filter();
    this.loading = false;

  }

  clearOrden() {
    this.loading = true;
    this.selectedOrden = null;
    this.filter();
    this.loading = false;

  }

  calculateDays(inputDate: any) {
    // Convierte la fecha de entrada a un objeto Date
    const [day, month, year] = inputDate.split('-').map(Number);
    const targetDate = new Date(year, month - 1, day); // Mes - 1 porque los meses en JavaScript empiezan desde 0

    const currentDate = new Date();
    // Establece la hora para evitar problemas con el tiempo
    currentDate.setHours(0, 0, 0, 0);
    const timeDiff = targetDate.getTime() - currentDate.getTime();
    const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24)); // Conversión de milisegundos a días

    // Genera el JSON de respuesta
    const response: any = {
      diasFaltantes: daysDiff,
    };

    // Determina si la fecha es hoy, mañana o futura
    if (daysDiff === 0) {
      response.fechaEstado = 'Hoy';
    } else if (daysDiff === 1) {
      response.fechaEstado = 'Mañana';
    }
    return response;
  }


  getBanks = async () => {
    try {
      const resp = await this.getBanksServer();
      this.banks = resp.res
    } catch (error) {
      console.error(error);
    }
  };

  getBanksServer = async () => {
    try {
      return this._user.getBanks({});
    } catch (error) {
      console.error(error);
    }
  };


  getSelectsBase = async () => {
    try {
      this.getSteps();

      this.getBanks();


      const from ={
        type:"SUSCRIPTOR"
      }


      const to ={
        type:"ALL"
      }


      const [subscriptors,providers] = await Promise.all([

        await this._user.getPersonPeople_type(from),
        await this._user.getPersonPeople_type(to),

      ]);


      subscriptors?.resp?.rows?.map((item: any) => {

        this.options.push({value:  item?.person?.code , text:`${ item?.person?.name }  ${ item?.person?.lastName? item?.person?.lastName:''} ${ item?.identitys[0]?.code_identity?.code }-${ item?.identitys[0]?.identity }`});

        return {
          code : item?.person?.code,
          name: `${ item?.person?.name } ${ item?.person?.lastName? item?.person?.lastName:''}  ${ item?.identitys[0]?.code_identity?.code }-${ item?.identitys[0]?.identity }`,
          identity:  item?.identitys[0]
        }
      });

      providers?.resp?.rows?.map((item: any) => {

        this.options2.push({value:  item?.person?.code , text:`${ item?.person?.name }  ${ item?.person?.lastName? item?.person?.lastName:''} ${ item?.identitys[0]?.code_identity?.code }-${ item?.identitys[0]?.identity }`});


        return {
          code : item?.person?.code,
          name: `${ item?.person?.name } ${ item?.person?.lastName? item?.person?.lastName:''} ${ item?.identitys[0]?.code_identity?.code }-${ item?.identitys[0]?.identity }`,
          identity:  item?.identitys[0]
        }
      });


    } catch (error) {
      console.error(error);
    }
  };


}
