
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from 'src/app/services/user/user.service';
import { mobiscroll, MbscCalendarOptions, MbscDatetimeOptions } from '@mobiscroll/angular';
import { DatePipe } from '@angular/common';
import * as alertifyjs from 'alertifyjs';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CommonService } from 'src/app/services/common.service';
import { DomSanitizer } from '@angular/platform-browser';
import { SocketioService } from 'src/app/services/socket/socketio.service';
import { saveAs } from 'file-saver';
import { NgxFileDropEntry } from 'ngx-file-drop';
import { MbscSelectOptions} from '@mobiscroll/angular';

import { invoice_types,invoice_types_code_obj,invoice_types_code_description_obj } from 'src/intefaces/invoice-type';
import { error } from 'console';




mobiscroll.settings = {
  lang: 'es',
  theme: 'ios',
  themeVariant: 'light',
};


@Component({
  selector: 'app-new-order',
  templateUrl: './new-order.component.html',
  styleUrls: ['./new-order.component.css'],
  providers: [DatePipe],

})
export class NewOrderComponent implements OnInit {

  options:any = [];
  invoice_types:any =invoice_types;
  invoice_types_code_obj :any = invoice_types_code_obj;
  invoice_types_code_description_obj :any = invoice_types_code_description_obj;

  invoice_type:any = "";
  load_Invoice_type:any;
  indexed:any = false;


  options2:any = [];

  selectedOption: string = '';

  @Output() close = new EventEmitter<any>();

  dataJson = {};
  providersObj :any= {};
  duplicates :any= {};


  accounts:any = [];
  account:any;
  account_error:any;
  shops:any = [];

  files:any= [];

  shop:any = '';
  mp_dynamic:any;

  shopsObj:any;
  type_transf:any = '';



  dateSetting: MbscCalendarOptions = {
    responsive: {
      small: {
        controls: ['calendar'],
        display: 'center',
      },
      medium: {
        display: 'bubble',
      },
    },
    lang: 'es',
    theme: 'ios',
    themeVariant: 'light',
  };

  birthday: any;
  birthdayString: any = '';
  form: any;
  form2: any;
  form3: any;
  formEjecutive: any;
  rate_error = false;
  rate_load = true;

  submit_disabled: any;
  submit_download_disabled: any;
  submit_download_create: any;


  person: any;
  searchPerson: any;
  step: any = 1;
  validateNumber: any;
  showDuplicate: any;

  type_identity: any = [];
  code_identity: any = [];
  marital_status: any;
  identityResponse: any;
  currencyResponse: any;
  result: any;
  buro: any = {};
  idInvoice: any;

  section: any = 1;
  idTransaction: any;
  errorMsg: any;
  identity: any;
  rate: any;

  idEjecutive: any = null;
  sessionUser: any;
  pagare: any = null;
  continue:any = null;


  viewForm = true;

  subscriptor: any = '';
  subscriptors: any = [];

  provider: any = '';
  description_invoice: any = '';

  providers: any = [];


  date_base: any = new Date;
  date_base_string :any;


  date_base1: any = new Date;
  date_base_string1 :any;


  date_base2: any = new Date;
  date_base_string2 :any;
  accountsObj :any = {};



  mobileSettingsFile: MbscDatetimeOptions = {
    display: 'bottom'
};

  mobileSettings: MbscDatetimeOptions = {
      display: 'bottom',
      themeVariant: 'dark',
  };


  desktopSettings: MbscDatetimeOptions = {
      display: 'bubble',
      touchUi: false
  };

  order_priority :any =1;
  order_priority_description :any;

  shopOptions: any[] = [];
  selectedShop: any;
  selectedTypeExpenses: any;
  type_expenses_obj: any = {};
  type_expenses: any = [];
  optionsTypeExpenses:any [] = [];


  queryParams: any;
  person_base_code :any;
  invoice_base_code :any;

  save_type_shop:any;
  save_type_expenses:any;


  constructor(
    private formBuilder: FormBuilder,
    private _user: UserService,
    private router: Router,
    public _auth: AuthService,
    private sanitizer: DomSanitizer,
    public socketService: SocketioService,
    private commonService: CommonService,
    private datePipe: DatePipe,
    private activatedRoute: ActivatedRoute,

  ) {

  }

  ngOnInit(): void {

    this.date_base_string = this.datePipe.transform(this.date_base, 'yyyy/MM/dd');
    this.date_base_string1 = this.datePipe.transform(this.date_base1,'yyyy/MM/dd');
    this.date_base_string2 = this.datePipe.transform(this.date_base2, 'yyyy/MM/dd');




    this._auth.auth$.subscribe((rep: any) => {
      if (rep) {
        this.sessionUser = this._auth.sessionUser;
      }
    });

    if (localStorage.getItem('idEjecutive')) {
      this.idEjecutive = localStorage.getItem('idEjecutive');
    }

    this.identity = localStorage.getItem('identity');
    localStorage.removeItem('identity');

    localStorage.removeItem('new_person');
    this.searchPerson = null;

    this.formEjecutive = this.formBuilder.group({
      type_identity: ['CI', [Validators.required]],
      code_identity: ['V', [Validators.required]],
      identity: [
        '',
        [Validators.required, Validators.minLength(6), Validators.maxLength(20)],
      ],
    });


    this.form = this.formBuilder.group({
      currency: ['BS', [Validators.required]],
      total_amount: ['', [Validators.required, Validators.min(0)]],
      plan: ['50'],
      type_invoice: ['FACTURA', [Validators.required]],
      invoice: ['']

    });

    this.getLists();

    this.getRate();

    this.getshops();

    this.getTypeExpense_2();

  }


  getLists = async () => {



    const [marital_status, type_identity, code_identity] = await Promise.all([

      await this._user.getPersonMaritalStatus(),
      await this._user.getPersonTypeIdentity(),
      await this._user.getPersonCodeIdentity()
    ]);





    this.marital_status = marital_status.resp;
    this.type_identity = type_identity.resp;
    this.code_identity = code_identity.resp;
  };

  submit = async () => {
    this.searchPerson = null;
    this.errorMsg = null;
    this.result = false;
    this.person = null;
    if (this.form.valid || true) {
      this.submit_disabled = true;
      const data = this.form.getRawValue();
      this.identityResponse = data.code_identity + data.identity;

      let params = {
        subscriber: {
          subscriber: 1,
          shop: this.sessionUser.shop,
          cash_register: 1,
          user: this.idEjecutive,
        },
        client: {
          type_identity: data.type_identity,
          code_identity: data.code_identity,
          identity: data.identity,
        },
        params: {
          plan_id: '63dc1738b5e22028eedb0521',
          currency: data.currency,
          total_amount: data.total_amount,
          chage_rate: this.rate?.amount
        },
      };

      try {
        let resp = await this._user.clientQuota(params);

        this.currencyResponse = data.currency;

        this.searchPerson = resp?.res;
        this.section = 2;
        this.viewForm= false;
        this.submit_disabled = false;
      } catch (error: any) {
        this.viewForm = true;
        const params = error?.error?.error;
        if (params?.code) {
          this.errorMsg = params?.msg;
        }

        this.submit_disabled = false;
      }
    }
  };

  sanitize(url: string) {
    //return url;
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  resetInvoice_type = async () => {


    try {


      if(this.invoice_type != ''){

        let data = this.invoice_types_code_obj[this.invoice_type];

        this.load_Invoice_type = true;

        this.subscriptor = "";
        this.provider = "";
        this.options2 = [];
        this.options = [];


        const from ={
          type:data?.from
        }


        const to ={
          type:data?.to
        }


        const [subscriptors,providers] = await Promise.all([

          await this._user.getPersonPeople_type(from),
          await this._user.getPersonPeople_type(to),

        ]);



        this.subscriptors =   subscriptors?.resp?.rows?.map((item: any) => {

          this.options.push({value:  item?.person?.code , text:`${ item?.person?.name }  ${ item?.person?.lastName? item?.person?.lastName:''} ${ item?.identitys[0]?.code_identity?.code }-${ item?.identitys[0]?.identity }`});

              return {
                   code : item?.person?.code,
                   name: `${ item?.person?.name } ${ item?.person?.lastName? item?.person?.lastName:''}  ${ item?.identitys[0]?.code_identity?.code }-${ item?.identitys[0]?.identity }`,
                   identity:  item?.identitys[0]
              }
        });

        this.providers = [];

        providers?.resp?.rows?.map((item: any) => {
          
          if(item?.identitys){

            this.options2.push({value:  item?.person?.code , text:`${ item?.person?.name }  ${ item?.person?.lastName? item?.person?.lastName:''} ${ item?.identitys[0]?.code_identity?.code }-${ item?.identitys[0]?.identity }`});

            this.providersObj[item?.person?.code]=  item?.person?._id;
            this.providers = [...this.providers,{
              code : item?.person?.code,
              name: `${ item?.person?.name } ${ item?.person?.lastName? item?.person?.lastName:''} ${ item?.identitys[0]?.code_identity?.code }-${ item?.identitys[0]?.identity }`,
              identity:  item?.identitys[0]
         }]
  
          }else{
            debugger
            console.error( `PRoveedor con problemas`,item?.person);
            
          }

        



      
        });

      }


    this.load_Invoice_type = false;

    } catch (error) {
      this.load_Invoice_type = false;

    }


  }


  creditSave = async () => {

    debugger;
    this.result = false;
    this.person = null;



    if (!this.submit_download_create) {
      this.submit_download_create = true;
      const data = this.form.getRawValue();
      this.identityResponse = data.code_identity + data.identity;



      const foundSubscriptor = this.subscriptors.find((subscriptor:any) => subscriptor.code === this.subscriptor);

      const foundProvider = this.providers.find((subscriptor:any) => subscriptor.code === this.provider);


      let priority = {
          code:this.order_priority,
          description:this.order_priority_description
       }


      let params = {
        "type_invoice":"PAYMENT_ORDER",
        subscriber: {
          subscriber:
          1,
          shop: this.sessionUser.shop,
          cash_register: 1,
          user: this.idEjecutive,
        },
        client: {

          type_identity: foundProvider?.identity?.type_identity?.code,
          code_identity: foundProvider?.identity?.code_identity?.code,
          identity: foundProvider?.identity?.identity,
      },

      client_suscriptor: {

          type_identity: foundSubscriptor?.identity?.type_identity?.code,
          code_identity: foundSubscriptor?.identity?.code_identity?.code,
          identity: foundSubscriptor?.identity?.identity,
        },

        params: {
          merchantNumber: data.invoice,
          merchantNumber2: data.invoice_oddo,
          "plan_id": "66a8f2de4359c15704b97099",
          referencesOrder: `${Math.floor(Math.random() * 10) + 1}`,
          currency: data.currency,
          total_amount: data.total_amount,
          chage_rate: this.rate?.amount,
          description : this.description_invoice,
          payment_params: [
            {
            /*  amount:
                this.searchPerson?.total_currency[data.currency]
                  ?.initiala_amount,*/

              amount:   data.total_amount,
              chage_rate: this.rate?.amount,
              currency: data.currency,
            },
          ],
          params:{

              client_payment:this.account,
              client_payment_code_dynamic: this.mp_dynamic,
              // "client_suscriptor":"66aab7409f06602889d06d2f"
              fvencimiento :this.date_base_string,
              femicion :this.date_base_string1,
              type_invoice : data.type_invoice,
              type_transf:this.type_transf,
              invoice_type:this.invoice_types_code_obj[this.invoice_type],
              priority,
              shop: this.save_type_shop,
              type_expense: this.save_type_expenses,
              indexed:this.indexed,
              currency:this.form.getRawValue()?.currency


          },
        },
      };
      debugger

      this.dataJson = params;

      try {
        let resp = await this._user.invoiceSave(params);
        // this.socketService.setResetstadistic(true);
        // aqui

        this.idInvoice = resp?.res?.invoice?.identifier;

       await this.invoiceFileUploadp(resp?.res?.invoice?.code);

        alertifyjs.success('Creacion exitosa');

        this.router.navigate([`/console/person/order-payments/${this.provider}/${resp?.res?.invoice?.code}`], {});

       // this.pagare = await this.generatePagares(resp?.res);

        this.submit_download_create = false;
      } catch (error: any) {
        debugger

        alertifyjs.error(error?.error?.error?.msg || 'Error al crear la orden');

        this.submit_download_create = false;
      }
    }
  };

  generatePagares = async (obj: any) => {
    let installments = obj.installMents.filter((row: any) => {
      return row.installMent.payment_status.code === 'PaymentPending';
    });
    installments = installments.map((row: any) => {
      row['person'] = obj.person;
      row['invoice'] = obj.invoice;
      return row;
    });
    let body = {
      template: 'template-pagare',
      config: {
        empty: '',
        pdfConfig: {
          page: {width: "87mm", height: "100%", format: "omitir"},
          margin: { top: '10px', right: '10px', bottom: '10px', left: '10px' }
        }
      },
      obj: {
        iterators: {
          pages: installments
        },
      },
      invoice: obj.invoice._id,
    };
    try {
      let resp = await this._user.generatePagare(body);
      return resp;
    } catch (e) {
      return null;
    }
  };

  downloadFile = async (url:any, extension: string, name: string) => {
    this.submit_download_disabled = true;
    let res = await this.commonService.download(url, extension);
    if(res) {
      saveAs(res, `${name}.${extension}`);
    }
    this.submit_download_disabled = false;
  }

  redirectTo() {
    this.router.navigate([`/`], {});
  }

  getRate = async (date = null, init = false, changeMount = false) => {
    try {
      this.rate_error = false;

      this.rate_load = false;

      let data: any = { subscriptor: 1 };


      if (date) {
        data = { date, subscriptor: 1 };
      }

      try {
        const respose = await this._user.getRate(data);
        if (!respose) this.rate_error = true;

        let rate = respose?.res;

        this.rate = rate;

      } catch (error) {
        console.log(error);
        this.rate_error = true;
        this.rate_load = false;
      }
    } catch (error) {
      console.log(error);
    }
  };



  setIdEjecutive() {
    const data = this.formEjecutive.getRawValue();
    let ci = data.code_identity + data.identity;

    localStorage.setItem('idEjecutive', ci);
    this.idEjecutive = ci;
  }

  moveCursorToEnd(event: any) {
    const input = event.target;
    input.selectionStart = input.selectionEnd = input.value.length;
  }

  continuar = () => {
    this.resetData();
  }

  resetData() {

    location.reload();

    /*
    this.viewForm = true;
    this.form = this.formBuilder.group({
      type_identity: ['CI', [Validators.required]],
      code_identity: ['V', [Validators.required]],
      currency: ['USD', [Validators.required]],
      identity: [
        this.identity,
        [Validators.required, Validators.minLength(6), Validators.maxLength(20)],
      ],
      total_amount: ['', [Validators.required, Validators.min(20)]],
      plan: ['50'],
      type_invoice: ['invoice', [Validators.required]],
      invoice: [''],
      invoice_oddo: [''],
    });

    this.section = 1;*/
  }

  resetSearchPerson(){

    this.form.get('invoice_oddo').setValue('');
    this.form.get('invoice').setValue('');
    this.searchPerson = null;
  }


  dateSet(e: any) {

    let date_base =  e.valueText;
    let  temp = date_base? date_base.split("/") : [];
    this.date_base_string = `${temp[2]}/${temp[1]}/${temp[0]}`;

  }

  dateSet1(e: any) {
    let date_base =  e.valueText;

    let  temp = date_base? date_base.split("/") : [];
    this.date_base_string1 =`${temp[2]}/${temp[1]}/${temp[0]}`;
     this.getOrderDuplicate()

    let  temp2 = date_base? date_base.split("/") : [];
    const date:any = `${temp[1]}/${temp[0]}/${temp[2]}`;

    this.getRate(date,false,true);

  }



  dateSet2(e: any) {

    let date_base =  e.valueText;

    let  temp = date_base? date_base.split("/") : [];
    this.date_base_string2 = `${temp[2]}/${temp[1]}/${temp[0]}`;

  }


  getAccounts = async () => {
    this.accounts = [];
    this.account = null;
    this.account_error = false;

    if(this.provider != ''){
      try {
        let resp = await this.commonService.getPayment_method({
          code_person: this.provider,
          person_type: this.invoice_types_code_obj[this.invoice_type]?.to
        });


        this.accounts = resp?.resp;

        this.accounts.map((value:any)=>{

        this.accountsObj[value._id] = value;




        });




    if(this.accounts?.length >0){

      this.account = this.accounts[0]._id;


    }else{

      this.account_error = true;

    }
      } catch (error) {
        console.log(error);
        this.account_error = true;

      }



      this.resetSearchPerson();
    }

  };



  public fileOver(event:any){
    console.log(event);
  }

  public fileLeave(event:any){
    console.log(event);
  }


  public dropped(files: NgxFileDropEntry[]) {
    for (const droppedFile of files) {
        if (droppedFile.fileEntry.isFile) {
            const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
            fileEntry.file((file: File) => {
                // Aquí puedes acceder al archivo real
                console.log('AQUI      ');
                console.log( file.name);
                if(/\.(jpg|jpeg|png|pdf)$/.test(file.name)) {
                  // Agregar el archivo a la lista
                  this.files.push(droppedFile);
                } else {
                  alertifyjs.error("Archivo con extensión no valida, se debe subir solo archivos de tipo imagen ó pdf");
                }
            });
        } else {
            // Era un directorio (se agregan directorios vacíos, de lo contrario solo archivos)
            const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
            console.log(droppedFile.relativePath, fileEntry);
        }
    }
}

public removeFile(index: number) {
    this.files.splice(index, 1); // Eliminar el archivo seleccionado de la lista
}


invoiceFileUploadp = async (code:any) => {


  return new Promise(async(resolve, reject) => {

const formData = new FormData();

formData.append("code_invoice", code);

for (const droppedFile of this.files) {
  if (droppedFile.fileEntry.isFile) {
      const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
      fileEntry.file((file: File) => {

          formData.append(droppedFile?.fileEntry?.name, file);

      });
  }
}


let resp = await this._user.invoiceFileUpload(formData);
resolve(resp)

   try {

   } catch (error) {

    reject(error)

   }
  });




}


onOptionChange(event: any) {

  console.log('Opción seleccionada:', this.subscriptor);
}



getshops = async () => {
  try {
    debugger;
    const resp = await this.getshopsServer();
    this.shops = resp?.res;

    let shops_resp = resp?.res;
    let shops:any = {};

    this.shops = shops_resp;

    shops_resp.map((value:any)=>{

      shops[value.code] = value;

      this.shopOptions.push({value:  value.code , text:`${ value?.name }`});


    });

    this.shopsObj = shops;



  } catch (error) {
    console.error(error);

  }




}


getTypeExpense_2 = async () => {
  try {

    const resp = await this.getTypeExpenseServer();
    this.type_expenses = resp?.res;

    let type_expenses_obj:any = {};


    this.type_expenses.map((value:any)=>{

      type_expenses_obj[value.code] = value?.name;
      this.optionsTypeExpenses.push({value:  value.code , text:`${ value?.name }`});


    });

    this.type_expenses_obj = type_expenses_obj;


  } catch (error) {
    console.error(error);

  }




}



getshopsServer = async () => {
  try{
      return this._user.getshops({});

    } catch (error) {
      console.error(error);


    }
}

getTypeExpenseServer = async () => {
  try{
      return this._user.getTypeExpense({});

    } catch (error) {
      console.error(error);


    }
}

getOrderDuplicate = async () => {
  try{

    const data = this.form.getRawValue();


   let  customer = this.providersObj[this.provider];
   let  amount= data.total_amount;
   let  femicion=this.date_base_string1;
   let  merchantNumber= data.invoice;


   const params ={
    customer,
    amount,
    femicion,
    merchantNumber

}


   if(customer && amount != '' &&  femicion  && femicion != '' && merchantNumber !== "" ){


    this.showDuplicate= false;

      let resp:any =  await this._user.getOrderDuplicate(params);

      this.duplicates = resp?.resp
      debugger

      this.showDuplicate= true;

   }


    } catch (error) {
      console.error(error);


    }
}

setTypeShop = async (item:any) => {
  try {
    debugger;

    this.shop = item;

    let query = {
      shop: this.shop!=''?this.shopsObj[this.shop]:'',

    }

   this.save_type_shop = query?.shop;

  } catch (error) {
    console.error(error);

  }



}


setTypeExpenses = async (item:any) => {
  try {
    this.save_type_expenses = item;

  } catch (error) {
    console.log(error);

  }
}




}
